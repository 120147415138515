export default function ExercisingLanguageSkillsDe() {
    return (<div class='post-body'>
        <h1 className='post-title'>Sprechen (und Schreiben!) auf Deutsch mithilfe von Monologen üben</h1>

        <p className='post-paragraph'>
            Beim Erlernen der deutschen und jeder anderen Fremdsprache ist fließende und korrekte Kommunikation das Hauptziel. Viele haben Schwierigkeiten, dieses Ziel zu erreichen, obwohl sie gute Kenntnisse in Grammatik und einen ausreichenden Wortschatz haben. Das Problem liegt in der unzureichenden Fokussierung auf diese Fertigkeit im Sprachlernprozess und in der Unkenntnis der Techniken, um diese zu üben. Im Folgenden lesen Sie, wie Sie ab Niveau A2 selbstständig die Kommunikation auf Deutsch durch Monologe üben können.
        </p>

        <p className='post-paragraph'>
            Der erste Schritt zur erfolgreichen Kommunikation ist natürlich das ständige Erlernen und Üben von Vokabeln und grammatikalischen Einheiten. Es ist sehr wichtig, dass wir beim Erlernen von Wörtern diese in Phrasen lernen, und nicht einzeln und außerhalb des Kontexts. Nehmen wir zum Beispiel das Thema <b>Urlaub am Meer</b>.
            Nun erinnern wir uns an die einfachsten Phrasen zu diesem Thema, mit denen wir unsere Assoziationen zu Aktivitäten am Meer beschreiben.
            Ziehen wir die ersten Ausdrücke aus dem Gedächtnis, die uns einfallen, heraus.
            Einige Phrasen zu diesem Thema wären zum Beispiel: <span className="highlighter-text-red"><b>am Strand liegen, sonnenbaden, im Meer schwimmen, heiß sein</b></span>, usw. Es wird deutlich, dass wir die Wörter in Kombinationen: Angabe-Verb, Adjektiv-Verb, Substantiv-Verb, usw lernen. Auf diese Weise können wir schneller und einfacher Sätze bilden, indem wir einfach das Subjekt hinzufügen.
        </p>

        <p className='post-paragraph'>
            Sehen wir uns an, wie wir uns zum Thema <b>Urlaub am Meer</b> in Form eines kurzen Monologs ausdrücken:
        </p>

        <p className='post-paragraph'>
            Nehmen wir die Phrase <span className="highlighter-text-red"><b>Urlaub am Meer machen</b></span> und erweitern sie, indem wir weitere Wörter hinzufügen, die wir zu diesem Thema kennen. So können wir sagen: <span className="highlighter-text-red"><b>Urlaub am Meer gern machen, zweimal pro Jahr Urlaub am Meer machen, im Sommer Urlaub am Meer machen</b></span> oder Variationen bilden: <span className="highlighter-text-red"><b>im Sommer ans Meer fahren, mit Freunden ans Meer fahren, mit dem Auto ans Meer fahren, heiß sein, Spaß haben.</b></span>
        </p>

        <p className='post-paragraph'>
            Beginnen wir nun mit der Bildung eines einfachen Satzes, indem wir eine der genannten Phrasen verwenden und das Subjekt hinzufügen: <span className="highlighter-text-red"><b>Ich mache gern Urlaub am Meer..</b></span> Jetzt setzen wir einfach fort, indem wir die anderen Phrasen und einfachere Konjunktionen wie <i>und, aber, oder, dass</i> oder <i>weil</i> verwenden:
        </p>

        <p className='post-paragraph'>
            <span className="highlighter-text-red">
                <b>
                    Ich mache gern Urlaub am Meer. Zweimal pro Jahr fahre ich mit meinen Freunden nach Kroatien. Es ist sehr heiß dort, <u>aber</u> wir haben viel Spaß. Wir schwimmen im Meer, essen <u>Eis</u> und trinken kalte Getränke. Ich hoffe, <u>dass</u> wir auch dieses Jahr auch zusammen gehen.
                </b>
            </span>
        </p>

        <p className='post-paragraph'>
            Wir haben einen Monolog auf dem Niveau A2 formuliert. Auf dem Niveau B1 kann der schon mithilfe von neuen Konjunktionen und Strukturen erweitert werden, bsp. mit: obwohl, Infinitiv mit zu und neuen Phrasen wie: <span className="highlighter-text-red"><b>am Strand sitzen, ein Buch lesen, den Seeblick genießen, Meeresrauschen hören.</b></span> Während wir einfachere Ausdrücke und Konjunktionen verwenden, haben wir bereits den "schlafenden" Wortschatz zu diesem Thema aktiviert und erinnern uns leichter an komplexere Phrasen und grammatikalische Strukturen. Dann können wir Schritt für Schritt damit beginnen, komplexere Sätze zu bilden.
        </p>

        <p className='post-paragraph'>
            <b>
                <span className="highlighter-text-red">
                    Ich mache gern Urlaub am Meer. Zweimal pro Jahr fahre ich mit meinen Freunden nach Kroatien. Es ist sehr heiß dort, aber wir haben viel Spaß. Wir schwimmen im Meer, essen Eis und trinken kalte Getränke. Ich hoffe, dass wir auch dieses Jahr auch zusammen gehen.
                </span>
                &nbsp;
                <span className="highlighter-text-purple">
                    <u>Obwohl</u> meine Freunde immer sonnenbaden, mag ich das nicht. Lieber lese ich ein Buch im Schatten. Ich genieße es auch, am Strand <u>zu sitzen</u> und Meeresrauschen <u>zu hören.</u>
                </span>
            </b>
        </p>

        <p className='post-paragraph'>
            Wir haben einen erweiterten Monolog mit einigen einfachen Ausdrücken und Konjunktionen erstellt. Mit jeder neu gelernten Phrase und der Grammatikstruktur können wir den Monolog noch erweitern.
        </p>

        <p className='post-paragraph'>
            Das Ziel dieser Methode ist es, bei der Antwort auf eine bestimmte Sprechsituation den bereits bekannten Wortschatz der deutschen Sprache zu aktivieren. Viele machen Fehler, indem sie versuchen, den im Kopf vorgestellten Text aus der Muttersprache ins Deutsche zu übersetzen. Auf diese Weise verheddern sie sich in komplizierten Strukturen, die sie nicht übersetzen können. Stattdessen ist das Ziel, sich die gegebene Situation zu visualisieren, Assoziationen zu "rufen" und sich an diejenigen Ausdrücke zu erinnern, die wir bereits kennen. Dann werden wir leichter auch andere Ausdrücke zu diesem Thema aktivieren und komplexere Sätze bilden können. Fehler sind in diesem Prozess natürlich unvermeidlich, aber das Wichtigste ist, dass wir die gelernten Phrasen verwenden und Techniken üben, um sie zu komplexeren Formen wie Sätzen und Texten zu kombinieren.
        </p>

        <p className='post-paragraph'>
            Es ist wichtig zu betonen, dass wir auf die gleiche Weise auch die Schreibfertigkeit trainieren können! Wählen Sie Ihr Lieblingsthema und beginnen Sie mit einem Monolog.
        </p>
    </div>)

}