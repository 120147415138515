import React from 'react';
import './HumanUnderstanding.css';

export default function HumanUnderstandingBa() {

    return (<div class='post-body'>
        <h1 className='post-title'>Jezik kao sredstvo međuljudskog razumijevanja</h1>
        <img className='post-headline-image' src={process.env.PUBLIC_URL + '/images/posts/human-understanding/sokrat-wide.webp'} alt="bookshelf" />

        <p className='post-paragraph'>
            Nijedan čovjek ne posjeduje dar čitanja tuđih misli. Nedostatak komunikacije je uzrok gotovo svih nesporazuma, a razgovor je najbolji ključ za razumijevanje ljudi. Ipak, sama komunikacija nije dovoljna za izgradnju dubljih odnosa sa drugima. U ophođenju sa različitim profilima ljudi moramo upotrijebiti prikladna komunikacijska sredstva. Ipak, postoje općevažeće djelotvorne metode i jezička sredstva za uspješne odnose sa drugima.        </p>
        <p>
            <i>…ophođenje s poštovanjem</i>
        </p>

        <p className='post-paragraph'>
            Svaka osoba je vrijedna poštovanja. Kada stupamo u razgovor s takvim uvjerenjem, neće nam se desiti da povrijedimo dostojanstvo drugoga. Poštovanje prema osobi tokom razgovora možemo izraziti tako što ćemo osobu prikladno oslovljavati i održavati čest kontakt očima. Naravno, osnova komunikacije u kojoj izražavamo poštovanje je i pažljivo slušanje sagovornika.
        </p>

        <p className='post-paragraph'>
            <i>…taktično izražavanje kritike</i>
        </p>

        <p className='post-paragraph'>
            Greške su neizostavni dio ljudske prirode. Na ovoj istini počiva princip tolerancije. Ona nas uči da razumijemo pogrešno ponašanje drugih ljudi, koje zapravo ne bismo trebali uzimati lično. Međutim, ne možemo se razvijati, ukoliko ne ukazujemo na pogrešno ponašanje jedni drugima. U tom slučaju je neophodno izraziti negativnu kritiku. Pritom je važno da na jedan taktičan način ukažemo na ono što nam smeta. Ovakav način komunikacijskog ophođenja vuče korijene iz filozofije stoicizma koja zagovara mudar način govora. “Ukoliko želimo komunicirati mudro, moramo stvari predočiti prikladno.” Zbog toga nam ovaj savjet može koristiti kada želimo izraziti negodovanje: Umjesto da optužujemo direktno i koristimo ti-formu, trebali bismo radije govoriti iz ja-pozicije. Na primjer, ukoliko nam neko neprestano postavlja privatna pitanja, možemo mu na sljedeći način objasniti zašto je to za nas neprihvatljivo:
            <b><i>“Ne osjećam se ugodno kada mi postavljaš takva pitanja, zato što takve stvari želim zadržati samo za sebe.”</i></b>
            Na ovaj način se osoba neće osjetiti napadnutom i vjerovatnije je da će razumjeti razlog naše frustracije.
        </p>

        <p>
            <i>.…dostojanstveno raspravljanje</i>
        </p>

        <p className='post-paragraph'>
            Uzrok većine konfliktnih situacija su različita ljudska stajališta. Trebamo razumjeti da se u pozadini naših razmimoilaženja često nalaze različite odgojne metode i životni uslovi u kojima smo odrastali. Pokušajmo zato razumjeti način razmišljanja druge osobe. Na ovaj način ćemo ostati mirni i staloženi u svakom dijalogu. Sokrat, kao majstor konstruktivnih dijaloga, “se nikada nije razbjesnio u razgovoru”. “Uvijek je bio učtiv i uzdržavao se da govori preoštro, čak i kada je doživljavao uvrede. Podnosio je mnoge uvrede strpljivo i uprkos njima uspijevao je da mirno i razumljivo okonča mnoge nesuglasice.” Pomoću sljedećih rečenica možemo razoružati osobu u njenoj ljutnji i sami ostati dostojanstveni u raspravi:
        </p>

        <p><b><i>Razumijem tvoje stajalište, ali ja mislim drugačije.</i></b></p>
        <p><b><i>Moje ubjeđenje dolazi iz iskustva koje sam stekao/stekla…</i></b></p>
        <p><b><i>Dozvoli mi da objasnim svoj stav.</i></b></p>
        <p><b><i>Uredu je da različito gledamo na stvar.</i></b></p>


        <i>…izražavanje zahtjeva</i>

        <p className='post-paragraph'>
            Ljudski ego ne podnosi imperativ. Zbog toga bi u komunikaciji s drugima trebali izbjegavati naredbe. Ukoliko želimo da drugi nešto urade, najbolje bi bilo da se držimo zlatne rječce “molim te”. Zlatna rječca i glagolski potencijal čine dobitnu kombinaciju kojom ćemo ostvariti željeno u svakom razgovoru.
            Primjer: <b><i>“Da li bi molim te mogao/mogla pročitati još jednom moj tekst?”</i></b> ili <b><i>“Da li bi molim te mogao/mogla danas otići u kupovinu?”</i></b>
            Ovakav način obraćanja najbolje možemo upotrijebiti u komunikativnim situacijama u uslužnim djelatnostima, kao na primjer u restoranima ili u kupovini. Iako je zadatak konobara da nam donese narudžbu, to ne znači da trebamo biti neljubazni.
            <b><i>“Ukoliko biste mi mogli donijeti jelovnik, bili biste ljubazni.”</i></b>,
            zvuči mnogo ljubaznije od naredbenog tona. Zato, kada idući put sretnete umornog konobara ili prodavača, dajte najbolje od sebe!
        </p>


    </div>)
}