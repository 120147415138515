import React from 'react';
import { useParams } from 'react-router-dom';
import categories from '../../data/categories';
import './Category.css';
import CategoryPreview from '../CategoryPreview/CategoryPreview';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';

export default function Category() {

    const params = useParams()

    const category = categories.find(c => c.id === parseInt(params.id))

    const { language } = React.useContext(LanguageContext)
    const { t, i18n } = useTranslation();

    function getTitle(category) {
        if (language.language === 'ba') {
            return category.ba.title;
        } else if (language.language === 'de') {
            return category.de.title;
        } else {
            return category.en.title;
        }
    }

    function getPosts(category) {
        if (language.language === 'ba') {
            return category.ba.posts;
        } else if (language.language === 'de') {
            return category.de.posts;
        } else {
            return category.en.posts;
        }
    }

    return (
        <>
            <div className="categoryContainer">
                <h1>{getTitle(category)}</h1>
                {
                    getPosts(category).map(post =>
                        <div className='post-preview'>
                            <CategoryPreview key={post.id}
                                title={post.title}
                                abstract={post.abstract}
                                imageUrl={post.imageUrl}
                                linkUrl={`/categories/${category.id}/posts/${post.id}`}
                                buttonText={i18n.t('explore')} />
                        </div>
                    )
                }
            </div>
        </>
    )
}