export default function SentenceBuildingBa() {

    return (<div class='post-body'>
        <h1 className='post-title'>Tvorba rečenice: Kraljevsko-glagolska metoda</h1>

        <p className='post-paragraph'>
            Glagol je kralj rečenice. On je glavni elemenat u rečeničnoj strukturi i nosioc značenja koji može izražavati radnju, stanje ili zbivanje. Zbog toga je kod tvorbe rečenice najbolje započeti sa glagolom i onda dodavati ostale rečenične dijelove. U nastavku slijede primjeri.</p>

        <p className='post-paragraph'>
            Sagovornik nam postavlja pitanje: „Koji jezik <b>učiš</b>?“. Razumijemo pitanje, ali imamo poteškoća da pravilno poredamo riječi. Fokusirajmo se na radnju, na glagol. Skicirajmo asociogram sa glagolom u sredini. Glagolu dodajmo odmah sljedeći najvažniji elemenat rečenice - to bi bio subjekat, <i>ich (ja)</i>. Sada imamo prostu rečenicu <b><i>Ich lernen. (Ja učiti.)</i></b>. Budući da glagoli u prvom licu uvijek završavaju na -e, „popravimo“ malo nastalu strukturu i dobićemo rečenicu sa glagolom u ispravnoj formi koja glasi <b><i>Ich lerne. (Ja učim.)</i></b>.
        </p>

        <p className='post-paragraph'>
            Sada nam nedostaje još jedna informacija - šta učimo (njemački), a ova informacija nosi titulu rečeničnog objekta - <b><i>Ich lerne Deutsch. (Ja učim njemački.)</i></b> Sa posljednjom informacijom je rečenica potpunija.</p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img1.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            Kako ćemo sada sagovorniku dati više informacija na njegovo pitanje? - Pomoću pitanja - Koliko dugo? Gdje?.  Oba odgovora pozicioniraćemo u rečenici nakon kombinacije <b><i>Ich lerne Deutsch...(Ja učim njemački...)</i></b>
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img2.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            Sa novim informacijama rečenica je postala kompleksnija - <b><i>Ich lerne Deutsch seit zwei Jahren im Kurs. (Učim njemački dvije godine na kursu.)</i></b> Važno pravilo koje treba naglasiti je poredak odredbi mjesta i vremena u rečenici. Kada rečenica sadrži više priloških odredbi u rečenici, prvo dolazi priloška odredba za vrijeme, pa nakon toga za mjesto.
        </p>

        <p>
            Rečenica se može još proširiti, ukoliko odgovorimo na pitanja: S kim? i Zašto?. Gdje sada trebamo pozicionirati  ove informacije? Odgovor ćemo konačno naći u pravilu TE-KA-MO-LO koje diktira sljedeći poredak priloških odredbi u rečenici: vrijeme - uzrok - način - mjesto.
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img3.png'} alt="bookshelf" />


        <p className='post-paragraph'>
            Kada se pravilo TE-KA-MO-LO naposlijetu primjeni, rečenica glasi: <b><i>Ich lerne Deutsch seit zwei Jahren aus Spaß mit meinen Kollegen im Kurs. ( Ja učim njemački dvije godine iz zabave sa mojim kolegama na kursu.)</i></b>
        </p>

        <p className='post-paragraph'>
            Što više informacija želimo dati, utoliko je rečenica kompleksnija, a pomenuto pravilo pomaže pri tvorbi.
        </p>

        <p className='post-paragraph'>
            Vježbajte sami pomoću sljedećeg pitanja: Was machen die Kinder im Park? (Šta rade djeca u parku?). Koristite asociogram kao pomoć:
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img4.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            Rečenica može da glasi: <b><i>Die Kinder spielen mit dem Hund im Park. (Djeca se igraju sa psom u parku.)</i></b> Navedite sada ostale informacije i pozicionirajte ih pravilno.
        </p>

        <p className='post-paragraph'>
            Cilj ove metode je naučiti kada ispravno poredati rečenične dijelove u odnosu na glagol.  Najprije sakupite sve informacije koje želite dati i započnite sa jednostavnom kombinacijom subjekat-predikat-objekat. Pravilo TE-KA-MO-LO će vam pomoći da proširite rečenicu.
        </p>

        <p className='post-paragraph'>
            Ovladajte rečeničnom strukturom vježbajući i koristeći navedeni metod.
        </p>

    </div>)

}