import React from "react";
import { LanguageContext } from '../../contexts/LanguageContext';
import aboutMe from '../../data/about-me';
import "./AboutMe.css";

export default function AboutMe() {

    const { language } = React.useContext(LanguageContext)

    function getTitle() {
        if (language.language === 'ba') {
            return 'O  meni';
        } else if (language.language === 'de') {
            return 'Über mich';
        } else {
            return 'About me';
        }
    }

    function getExperienceTitle() {
        if (language.language === 'ba') {
            return 'Iskustvo';
        } else if (language.language === 'de') {
            return 'Erfahrung';
        } else {
            return 'Experience';
        }
    }

    function getEducationTitle() {
        if (language.language === 'ba') {
            return 'Obrazovanje';
        } else if (language.language === 'de') {
            return 'Ausbildung';
        } else {
            return 'Education';
        }
    }

    function getParagraphs() {
        if (language.language === 'ba') {
            return aboutMe.ba.paragraphs;
        } else if (language.language === 'de') {
            return aboutMe.de.paragraphs;
        } else {
            return aboutMe.en.paragraphs;
        }
    }

    function getContent() {
        const ps = getParagraphs()
        return ps.map(p => {
            return <p>{p}</p>
        });
    }

    function getExperienceParagraphs() {
        if (language.language === 'ba') {
            return aboutMe.ba.experience;
        } else if (language.language === 'de') {
            return aboutMe.de.experience;
        } else {
            return aboutMe.en.experience;
        }
    }

    function getExperienceContent() {
        const ps = getExperienceParagraphs()
        return (<ul>
            {ps.map(p => {
                return <li className="experience-item">{p}</li>
            })}
        </ul>);
    }

    function getEducationParagraphs() {
        if (language.language === 'ba') {
            return aboutMe.ba.education;
        } else if (language.language === 'de') {
            return aboutMe.de.education;
        } else {
            return aboutMe.en.education;
        }
    }

    function getEducationContent() {
        const ps = getEducationParagraphs()
        return (<ul>
            {ps.map(p => {
                return <li className="experience-item">{p}</li>
            })}
        </ul>);
    }

    return (
        <div className="container">
            <div className="row" >

                <h1 className="section-header">{getTitle()}</h1>
                <div className='col-lg-6 col-sm-12 left-section'>
                    {getContent()}
                </div>
                <div className='col-lg-6 col-sm-12 left-section'>
                    <img className='portrait' src="/images/portrait.png" alt="portrait" />
                    <h3>{getExperienceTitle()}</h3>
                    {getExperienceContent()}
                    <h3>{getEducationTitle()}</h3>
                    {getEducationContent()}
                </div>
            </div>
        </div>

    )
}
