export default function SentenceBuildingDe() {

    return (<div class='post-body'>
        <h1 className='post-title'>Satzbildung: Verb-König-Methode</h1>

        <p className='post-paragraph'>
            Das Verb ist der Satzkönig. Es ist das Hauptglied in der Satzstruktur und der Bedeutungsträger, der eine Handlung, einen Zustand oder ein Geschehnis ausdrücken kann. Deswegen ist es beim Satzbau am besten, mit dem Verb zu beginnen und danach andere Satzelemente zuzufügen. Im folgenden werden Beispiele zum Üben gegeben.
        </p>

        <p className='post-paragraph'>
            Der Sprechpartner fragt uns: „Welche Sprache <b>lernst</b> du?“. Wir verstehen die Frage, haben aber bei der Antwort Schwierigkeiten, die Wörter richtig zu ordnen. Fokussieren wir uns auf die Handlung, auf das Verb. Skizzieren wir ein Assoziogramm mit dem Verb im Mittelpunkt. Rund  um das Verb fügen wir dann gleich das nächste wichtige Satzelement zu - das wäre das Subjekt, <i>ich</i>. Jetzt haben wir die Kombination <b><i>Ich lernen..</i></b> Da die Verben in der ersten Person Singular immer auf -e enden, „korrigieren“ wir nur ein bisschen diese Kombination und bekommen dann den Satz <b><i>Ich lerne..</i></b>
        </p>

        <p className='post-paragraph'>
            Jetzt fehlt uns noch eine Information - was wir lernen (Deutsch) und diese Information trägt den Titel des Satzobjektes. - <b><i>Ich lerne Deutsch</i></b>. Mit der letzten Information ist der Satz vollständiger.
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img1.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            Wie geben wir jetzt dem Sprechpartner weitere Informationen auf seine Frage? - Mithilfe von Fragen - <b><i>Wie lange? Wo?</i></b>. Die zwei Antworten positionieren wir im Satz nach der Kombination <b><i>Ich lerne Deutsch...</i></b>
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img2.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            Mit neuen Informationen ist der Satz komplexer geworden - <b><i>Ich lerne Deutsch seit zwei Jahren im Kurs.</i></b> Eine wichtige Regel zu betonen ist die Ort- und Zeitstellung. Bei mehreren Angaben im Satz kommt zuerst die Temporal- und dann die Lokalangabe.
        </p>

        <p className='post-paragraph'>
            Der Satz kann noch erweitert werden und zwar, wenn wir auf die Fragen: <b><i>Mit wem?</i></b> und <b><i>Warum?</i></b> Antworten geben. Wo sollen dann diese Informationen positioniert werden? Die Antwort finden wir schließlich in der TE-KA-MO-LO-Regel (temporal-kausal-modal-lokal), die folgende Ordnung der Satzangaben bestimmt: Zeit - Ursache - Modus (Weise) - Ort, oder einfacher erklärt, man beantwortet folgenderweise auf die Fragen: 1. Wann/Wie lange? - 2. Warum? - 3. Wie? - 4. Wo?.
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img3.png'} alt="bookshelf" />


        <p className='post-paragraph'>
            Wenn man die TE-KA-MO-LO-Regel schließlich anwendet, lautet der Satz: <b><i>Ich lerne Deutsch seit zwei Jahren aus Spaß mit meinen Kollegen im Kurs.</i></b>
        </p>

        <p className='post-paragraph'>
            Je mehr Informationen wir geben wollen, desto komplexer ist die Satzbildung und die genannte Regel hilft dabei.
        </p>

        <p className='post-paragraph'>
            Üben Sie selbst mit der folgenden Frage: Was machen die Kinder im Park?  Nutzen Sie das Assoziogramm als Hilfe:
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img4.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            Der Satz kann lauten: <b><i>Die Kinder spielen mit dem Hund im Park.</i></b> Nennen Sie jetzt weitere Informationen und positionieren Sie sie richtig.
        </p>

        <p className='post-paragraph'>
            Das Ziel dieser Methode ist zu lernen, wie man Satzelemente rund um das Verb richtig positioniert. Sammeln Sie zuerst alle Informationen, die Sie geben wollen und beginnen Sie mit der einfachen Kombination, Subjekt-Prädikat-Objekt. Die Regel TE-KA-MO-LO hilft Ihnen bei der Satzerweiterung.
        </p>

        <p className='post-paragraph'>
            Beherrschen Sie die Satzstuktur mithilfe der beschriebenen Methode.
        </p>

    </div>)

}