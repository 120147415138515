import IdealOfGoodnessBa from "./IdealOfGoodnessBa";
import IdealOfGoodnessDe from "./IdealOfGoodnessDe";
import IdealOfGoodnessEn from "./IdealOfGoodnessEn";
import './IdealOfGoodness.css';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

export default function IdealOfGoodness() {

  const { language } = React.useContext(LanguageContext)

  if (language.language === 'ba') {
    return IdealOfGoodnessBa()
  } else if (language.language === 'de') {
    return IdealOfGoodnessDe()
  } else {
    return IdealOfGoodnessEn()
  }

}