import categories from '../../../data/categories';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import './PowerOfLanguageUse.css';

export default function PowerOfLanguageUse() {

    const { language } = React.useContext(LanguageContext)

    function getTitle() {
        if (language.language === 'ba') {
            return categories[0].ba.posts[0].title;
        } else if (language.language === 'de') {
            return categories[0].de.posts[0].title;
        } else {
            return categories[0].en.posts[0].title;
        }
    }

    function getBosnianContent() {
        return (<>
            <p className='post-paragraph'>
                Jezik je sredstvo pomoću kojeg oblikujemo i izražavamo svoje misli. Između našeg svijeta misli i izričaja postoji potpuno jasna korelacija. Što više čitamo, promišljamo i ispravnije percipiramo svijet, utoliko bolje se formiraju naše misli, te naš jezički izražaj postaje precizniji i razumljiviji. Sposobnost izražavanja predstavlja rezultat naše spoznaje svijeta s jedne strane i jezičkih vještina koje smo naslijedili i razvili s druge, a kojima predočavamo stečene spoznaje.
            </p>
            <p className='post-paragraph'>
                Pri tome želim naglasiti da je učenje jezika proces koji se odvija u korak sa unutrašnjim samorazvojem. Taj proces je mnogo više od učenja kako se „snaći“ u stranoj zemlji, kako pronaći put do supermarketa ili novi posao.
            </p>
            <p className='post-paragraph'>
                Želja za učenjem stranog jezika bi trebala biti motivisana ciljem samospoznaje u dodiru sa drugom kulturom, zato što sami sebe mnogo bolje percipiramo putem upoznavanja drugih. Samospoznaja je polazna odrednica za razumijevanje svijeta koji nas okružuje.
            </p>
            <p className='post-paragraph'>
                Putem učenja, promišljanja, razgovora i refleksije formiramo naše biće poput mozaika koji otkrivamo svijetu pažljivo biranim jezičkim sredstvima.
            </p>
        </>)
    }

    function getGermanContent() {
        return (<>
            <p className='post-paragraph'>
                Die Sprache ist das Mittel, mit dem wir unsere Gedanken bilden und ausdrücken. Zwischen unserer Gedankenwelt und dem Ausgesprochenen besteht eine ganz klare Korrelation. Je mehr wir lesen, nachdenken, und die Welt besser wahrnehmen, desto klarer werden unsere Gedanken gebildet, die dann als präzise, verständliche Sprachmittel geäußert werden. Ausdrucksmöglichkeiten stellen das Ergebnis unserer Welterkenntnis auf einer Seite und der erworbenen und entwickelten Sprachfähigkeiten auf der anderen Seite dar, mit denen wir die Welterkenntnisse zum Ausdruck bringen.
            </p>
            <p className='post-paragraph'>
                Damit will ich betonen, dass das Sprachlernen ein Prozess ist, der Hand in Hand mit der inneren Selbstentwicklung läuft. Es ist immer mehr als lernen, sich in einem fremden Land „zurechtfinden zu können“, den Weg zum Supermarkt oder einen neuen Job zu finden.
            </p>
            <p className='post-paragraph'>
                Der Wunsch nach dem Sprachenlernen sollte als Ziel haben, sich selbst in Kontakt mit der anderen Kultur zu erkennen, weil wir uns selbst durch das Kennenlernen der anderen besser wahrnehmen. Die Selbsterkenntnis ist der Ausgangspunkt zum Verständnis der Welt, die uns umgibt.
            </p>
            <p className='post-paragraph'>
                Durch das Lesen, Nachdenken, Sprechen und Reflektieren gestalten wir das Mosaikbild unseres Wesens, das wir mit sorgfältig ausgewählten Sprachmitteln der Welt entdecken.
            </p>
        </>)
    }

    function getEnglishContent() {
        return (<>
            <p className='post-paragraph'>
                Language is a medium for creating and expressing our thoughts. There is a strong bond between our world of thoughts and our verbal expressions. The more we read and think and the more we perceive the world in a proper way, the clearer our thoughts become, and so do our verbal expressions. Our possibilities to express ourselves are a product of understanding the world and the language skills that we acquired and developed with time.
            </p>
            <p className='post-paragraph'>
                Here I want to emphasize that language learning is a process which goes hand in hand with our self-development. It's more than “finding our way” in some foreign land, finding the supermarkt or a new job.
            </p>
            <p className='post-paragraph'>
                A language learning goal should be finding and understanding ourselves through contact with another culture. The self-understanding is a springboard for understanding the world.
            </p>
            <p className='post-paragraph'>
                While reading, thinking, speaking and reflecting, we are creating the mosaic of our human being which we show to the world through our specific chosen language expression.
            </p>
        </>);
    }

    function getContent() {
        if (language.language === 'ba') {
            return getBosnianContent();
        } else if (language.language === 'de') {
            return getGermanContent();
        } else {
            return getEnglishContent();
        }
    }

    return (<div class='post-body'>
        <h1 className='post-title'>{getTitle()}</h1>
        <img className='post-headline-image' src={process.env.PUBLIC_URL + '/images/posts/power-of-language/bookshelf-stretched.png'} alt="bookshelf" />
        {getContent()}
    </div>)
}