import React from 'react';
import './Quote.css';

export default function Quote(properties) {
    return (
        <section className="quote-background quote-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/images/flowers.jpg'})` }}>
            <div className="container py-2">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col col-lg-9 col-xl-7">
                        <div className="card quote" style={{ borderRadius: '15px' }}>
                            <div className="card-body p-3">
                                <figure className="text-center mb-0">
                                    <blockquote className="blockquote">
                                        <p className=" quote-text">
                                            <i className="fas fa-quote-left fa-xs text-primary"></i>
                                            <span className="lead font-italic quote-text">{properties.text}</span>
                                            <i className="fas fa-quote-right fa-xs text-primary"></i>
                                        </p>
                                    </blockquote>
                                    {properties.author}
                                </figure>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}