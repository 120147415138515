export default function ExercisingLanguageSkillsEn() {

    return (<div className='post-body'>
        <h1 className='post-title'>Exercising the Skill of Speaking (and Writing!) in German by Creating Monologues</h1>


        <p className='post-paragraph'>
            When learning German or any other foreign language, fluent and correct communication is the key goal. Many people have difficulty achieving this, even though they have a good understanding of grammar and a sufficient vocabulary. The problem lies in the insufficient focus on this skill in the language learning process and the lack of knowledge of techniques to practice it. Below, you can read how you can practice communication in German through monologues, starting from level A2.
        </p>

        <p className='post-paragraph'>
            The first step to successful communication is certainly the constant acquisition and practice of vocabulary and grammatical units. It is very important that when we learn words, we learn them in phrases, never individually and out of context. Let's take the topic of <b>Urlaub am Meer (holiday at the sea)</b> as an example. Now let's recall the basic phrases on this topic that describe our associations with activities at the sea. Let's "pull out" from memory the first expressions that come to mind. These would be approximately the following phrases: <span className="highlighter-text-red-bolded">am Strand liegen (to lie on the beach), sonnenbaden (to sunbathe), im Meer schwimmen (to swim in the sea), warm sein (to be warm)</span>, etc. As we can see, we learn words in combinations: adverbial phrase-verb, adjective-verb, noun-verb, adverb-verb,etc. By learning the words in combinations we will more quickly and easily form sentences by simply adding the subject.
        </p>

        <p className='post-paragraph'>
            Let's see how we can express ourselves on the topic of <b>Urlaub am Meer (holiday at the sea)</b> in the form of a short monologue:
        </p>

        <p className='post-paragraph'>
            Let's take the phrase <span className="highlighter-text-red-bolded">Urlaub am Meer machen</span> and expand it by adding other words we know on this topic. So we can say: <span className="highlighter-text-red-bolded">Urlaub am Meer gern machen (to enjoy vacation at the sea), zweimal pro Jahr Urlaub am Meer machen (to go on vacation at the sea twice a year), im Sommer Urlaub am Meer machen (to go on vacation at the sea in the summer)</span>, or create variations: <span className="highlighter-text-red-bolded">im Sommer ans Meer fahren (to go to the sead in the summer), mit Freunden ans Meer fahren (to go to the sea with the friends), mit dem Auto ans Meer fahren (to drive to the sea by car), warm sein (to be warm), Spaß haben (to have fun)..</span> Now, let's start by forming a simple sentence by using one of the mentioned phrases and adding the subject: <span className="highlighter-text-red-bolded">Ich mache gern Urlaub am Meer..</span>. Now, just continue, using the other phrases and simple conjunctions like <i>und (and), aber (but), oder (or), dass (that),</i> or <i>weil (because)</i>:
        </p>

        <p className='post-paragraph'>
            <span className="highlighter-text-red-bolded">
                Ich mache gern Urlaub am Meer. Zweimal pro Jahr fahre ich mit meinen Freunden nach Kroatien. Es ist sehr heiß dort, <u>aber</u> wir haben viel Spaß. Wir schwimmen im Meer, essen Eis <u>und</u> trinken kalte Getränke. Ich hoffe, <u>dass</u> wir auch dieses Jahr auch zusammen gehen.
            </span>
        </p>

        <p className='post-paragraph'>
            We have formulated a monologue at the A2 level. At the B1 level, it can already be expanded with the help of new conjunctions and structures, for example, with: obwohl, Infinitiv mit zu, and new phrases like: <span className="highlighter-text-red-bolded">am Strand sitzen (to sit on the beach), ein Buch lesen (to read a book), den Seeblick genießen (to enjoy the sea view), Meeresrauschen hören (to listen to the sound of the sea).</span> During the use of simpler expressions and conjunctions, we have already activated the "dormant" vocabulary on the given topic, and we remember complex phrases and grammatical structures more easily. Then, step by step, we can start forming more complex sentences.
        </p>

        <p className='post-paragraph'>
            <span className="highlighter-text-red-bolded">
                Ich mache gern Urlaub am Meer. Zweimal pro Jahr fahre ich mit meinen Freunden nach Kroatien. Es ist sehr heiß dort, aber wir haben viel Spaß. Wir schwimmen im Meer, essen Eis und trinken kalte Getränke. Ich hoffe, dass wir auch dieses Jahr auch zusammen gehen.
            </span>
            &nbsp;
            <span className="highlighter-text-purple-bolded">
                <u>Obwohl</u> meine Freunde immer sonnenbaden, mag ich das nicht. Lieber lese ich ein Buch im Schatten. Ich genieße es auch, am Strand <u>zu sitzen</u> und Meeresrauschen <u>zu hören.</u>
            </span>
        </p>

        <p className='post-paragraph'>
            We have now created an extended monologue with some simple expressions and conjunctions.
        </p>

        <p className='post-paragraph'>
            The goal of this method is to activate the already known vocabulary of the German language when responding to a certain speaking situation. Many make the mistake of trying to translate the imagined text in their head from their native language into German. In this way, they get tangled up in complicated structures they do not know how to translate. Instead, the goal is to visualize the given situation, "recall" associations, and remember the basic expressions we already know. Then we will more easily activate other expressions on the given topic and form more complex sentences. Mistakes are, of course, inevitable in this process, but the most important thing is that we use the learned phrases and practice techniques to combine them into more complex forms such as sentences and texts.
        </p>

        <p className='post-paragraph'>
            It is important to emphasize that we can also train our writing skills in the same way! Choose your favorite topic and begin a monologue.
        </p>
    </div>)

}