import KindWordsBa from "./KindWordsBa";
import KindWordsDe from "./KindWordsDe";
import KindWordsEn from "./KindWordsEn";
import './KindWords.css';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

export default function SentenceBuilding() {

  const { language } = React.useContext(LanguageContext)

  if (language.language === 'ba') {
    return KindWordsBa()
  } else if (language.language === 'de') {
    return KindWordsDe()
  } else {
    return KindWordsEn()
  }

}