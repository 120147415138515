import React from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import FlagGroup from '../FlagGroup/FlagGroup';
import './Footer.css';

export default function Footer() {

    const { language } = React.useContext(LanguageContext)

    function getLogo() {
        function getLogoSuperscript() {
            if (language.language === 'de') {
                return "Die Schönheit der Sprache"
            } else if (language.language === 'en') {
                return "The Beauty of language"
            } else {
                return "Ljepota jezika"
            }
        }

        function getLogoSubscript() {
            // return "";

            if (language.language === 'de') {
                return "von Nermana"
            } else if (language.language === 'en') {
                return "by Nermana"
            } else {
                return "sa Nermanom"
            }
        }

        return <><span >{getLogoSuperscript()} {getLogoSubscript()}</span></>
    }

    return (
        <div className="container">
            <footer className="footer d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div className="    d-flex align-items-center">
                    <span className="mb-3 mt-3 mb-md-0 text-muted">{getLogo()}</span>
                </div>

                <div className='flag-group-footer'><FlagGroup /></div>
            </footer>
        </div>
    )
}