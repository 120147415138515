const aboutMe = {
    ba: {
        paragraphs: [
            "Narednih nekoliko redaka ću iskoristiti da vas upoznam sa određenim crtama svog profesionalnog i ličnog razvoja, kao i da obrazložim svoj motiv za pisanje bloga.",
            "Profesorica sam njemačkog jezika i književnosti po struci. Nakon završetka studija moj profesionalni razvoj se kretao u pravcu izučavanja metodike. Shodno tome sam prethodnih godina sakupljala znanje upravo iz didaktičko-metodičke oblasti, radeći kao predavačica na kursevima njemačkog jezika, te kao profesorica u gimnaziji. Usvajajući vještine predavača, uporedo sam završila obuku za licenciranu ispitivačicu Austrijske jezičke diplome (ÖSD), kao i obuku za trenericu učenja.",
            "Izučavanje jezika je cjeloživotni proces i ne može se ograničiti samo na profesionalnu sferu djelovanja. Uporedo sa učenjem njemačkog jezika, kroz formalno i neformalno obrazovanje sam izučavala engleski, španski i ruski jezik. Strast za učenje stranih jezika u mom slučaju ne pokreće želja za pukim usvajanjem fonetike, savladavanjem gramatičkih pravila ili tečne komunikacije, već potreba za upoznavanjem sebe i svijeta kroz percepciju mentaliteta druge kulture. S tom namjerom sam na svojim putovanjima u kontaktu sa ljudima diljem svijeta koristila svaku priliku za komunikaciju na stranim jezicima, kako bih što bolje osjetila duh zemlje u kojoj boravim. Jezik posmatram kao instrument koji nam omogućava razumijevanje različitih svjetonazora. Samo učenje jezika nameće i upoznavanje sa društveno-historijskim tokovima svijeta, a koje je moguće i potpuno samo u doticaju sa ostalim antropološkim naukama.",
            "Strani jezik ne učimo radi jezika, već s ciljem djelovanja i davanja doprinosa svijetu u kojem živimo, što jeste egzistencijalna svrha našeg bića. Stoga ovaj blog ima višestruki cilj. Prvenstveno želim podijeliti svoje metodičko-didaktičko znanje u vidu praktičnih savjeta za učenje njemačkog i drugih jezika. Krajnja namjera je kroz dijeljenje ličnih iskustava i kroz tekstove prožete religijskim, psihološkim, socijološkim i književnim promišljanjima potaći na učenje stranih jezika i inspirisati na upotrebu riječi kao najmoćnijeg sredstva u procesu izgradnje i poboljšanja društva kojem pripadamo."
        ],
        experience: [
            "Profesorica njemačkog jezika i književnosti, licencirana ÖSD ispitivačica i trenerica za učenje",
            "Preko 8 godina radnog iskustva u podučavanju",
            "Učešće sa različitim radionicama na BHDLT-u i IDT-u"
        ],
        education: [
            "2015 - Stipendija kulturoloških studija na Univerzitetu u Vechti",
            "2016 - GIP (Partnerska) stipendija na Univerzitetu Würzburg",
            "2016 - Završen bachelor i master studij Njemačkog jezika i književnosti na Filozofskom fakultetu u Sarajevu"
        ]
    },
    en: {
        paragraphs: [
            "In folgenden Sätzen möchte ich euch mit bestimmten Aspekten meiner professionellen und persönlichen Entwicklung vertraut machen und mein Motiv zum Blogschreiben erläutern.",
            "Ich bin Deutschlehrerin von Beruf. Nach dem Studienabschluss ging meine professionelle Laufbahn in Richtung der Methodikforschung. Deshalb habe ich in den nächsten Jahren Kenntnisse aus dem didaktisch-methodischen Bereich gesammelt, während ich als Kursleiterin der deutschen Sprache und als Deutschlehrerin am Gymnasium tätig war. Gleichzeitig mit der Entwicklung meiner Fertigkeiten als Kursleiterin habe ich die Lehre zur lizenzierten ÖSD-Prüferin sowie zum Lerncoaching abgeschlossen.",
            "Die Sprachforschung ist ein lebenslanger Prozess und kann nicht nur auf den professionellen Tätigkeitsbereich begrenzt werden. Neben der deutschen Sprache habe ich gleichzeitig durch formelle und informelle Ausbildung auch Englisch, Spanisch und Russisch erlernt. Meine Leidenschaft für das Sprachenlernen kommt nicht aus dem Wunsch nach dem Beherrschen der Phonetik, der Grammatikregeln oder der flüssigen Kommunikation, sondern vom Bedarf nach der Selbst- und Welterkenntnis durch die Wahrnehmung der Denkweise anderer Kulturen. Mit dieser Absicht habe ich auf meinen Reisen in Kontakt mit Menschen aus verschiedenen Seiten der Welt jede Möglichkeit genutzt, auf den Fremdsprachen zu kommunizieren, so dass ich den Geist des besuchten Landes besser erleben kann. Die Sprache betrachte ich als Mittel zum Verstehen unterschiedlicher Sichtweisen. Beim Sprachenlernen muss man sich auch mit der gesellschaftlichen und historischen Entwicklung der Weltereignissen vertraut machen und dies ist nur durch den Kontakt mit anderen anthropologischen Wissenschaften möglich.",
            "Eine Fremdsprache lernen wir nicht selbst der Sprache wegen, sondern um zu handeln und der Welt, in der wir leben, unseren Beitrag zu geben, was gerade den existentiellen Sinn unseres Wesens darstellt. Infolgedessen hat dieser Blog das mehrfache Ziel. Vor allem möchte ich meine methodisch-didaktischen Kenntnisse im Sinne von praktischen Tipps zum Lernen der deutschen und anderen Sprachen übermitteln. Mein Endziel ist, mithilfe meiner persönlichen Erfahrungen und durch Texte, basierend auf dem religiösen, psychologischen, soziologischen und literarischen Nachdenken, zum Fremdsprachenlernen zu bewegen und zum Gebrauch des Wortes als mächtigsten Mittel zur Bildung und Verbesserung der Welt zu inspirieren."
        ],
        experience: [
            "German teacher, Licensed ÖSD-Examiner, Learncoach",
            "8+ years working experience in teaching",
            "Participation with different workshops on BHDLT and IDT"
        ],
        education: [
            "2015 - Cultural Studies Scholarship at the University in Vechta",
            "2016 - GIP-scholarship at the University in Würzburg",
            "2016 - Finished Bachelor- and Master Study of German Language and Literature on the Philosophy Faculty in Sarajevo"
        ]
    },
    de: {
        paragraphs: [
            "In the next few lines I want you to get to know some of the aspects of my professional and private development and to explain what motivated me to start writing my blog.",
            "I'm a teacher of German language and literature. After finishing my studies I started working on my professional growth by learning methodology. Therefore I have been gaining experience in the field of didactic and methodology in the last few years, working as a German teacher on courses and in gymnasium. While gaining teaching skills I finished education for the Examiner of the Austrian language certificate (ÖSD) and education for the Learning coach.",
            "Language learning is a whole-life-process and goes far beyond the professional field of interest. While studying German language I have been also learning English, Spanish and Russian through institutional and non-institutional education. The passion for language learning comes in my case not from desire to master phonetics, grammar rules or fluent communication, but from the need of getting to know myself and the world through perception of the way of thinking in other cultures. I was led by that intention during my travels and with people from different parts of the world I used every chance to speak on foreign language to feel the spirit of the land I was visiting. I perceive language as an instrument which gives us the possibility to understand different points of views. Language learning requires also being in touch with social and historical world changes and issues, which is possible and complete only by interacting with other anthropological studies.",
            "We don’t learn language just to gain language knowledge, but to act and make contribution to the world we live in, which is the existential purpose of our human being. Therefore, this blog has multiple missions. First of all, I want to share my teaching knowledge by giving pieces of advice for learning German and other languages. The main goal of writing this blog is to inspire people to learn foreign languages and to use the words as the most powerful instruments for building and improving the society, by sharing my personal experiences and texts based on religious, psychological, sociological and literature thinking."
        ],
        experience: [
            "Deutschlehrerin, lizenzierte ÖSD-Prüferin und Lerncoacherin",
            "Über 8 Jahre der Arbeitserfahrung im Lehrerbereich",
            "Teilnahme mit verschiedenen Workshops an der BHDLT und IDT"
        ],
        education: [
            "2015 - Kulturwissenschaftliches Stipendium an der Universität Vechta",
            "2016 - GIP-Stipendium an der Universität Würzburg",
            "2016 - Abgeschlossenes Bachelor- und Masterstudium der Deutschen Sprache und Literatur an der Philosophischen Fakultät Sarajevo"
        ]
    }
}

export default aboutMe;