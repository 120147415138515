export default function SentenceBuildingEn() {

    return (<div class='post-body'>
        <h1 className='post-title'>Sentence formation: Verb-King-Method</h1>

        <p className='post-paragraph'>
            A verb is a sentence king. It is a main element in the sentence order and a carrier of meaning which can express an act, a state or a happening. Based on that fact it is best to begin with a verb while making a sentence and to order other sentence elements accordingly. There are the following examples to show the rule.
        </p>

        <p>
            The interlocutor asks us for example: “Which language do you <b>learn?</b>”. We understand the question, but we may have difficulties ordering the words in a proper way. Let's focus on the verb and sketch it in the middle. Then we add the second most important sentence element to the verb - that would be the subject, <i>ich (I)</i>. Now we have a simple sentence <b><i>Ich lernen. (I learn.)</i></b>. According to the rule that the verbs in the first person always end on -e, we have to “fix” the structure a little bit and then we have the sentence with a verb in the right form, which is <b><i>Ich lerne. (I learn.)</i></b>.
        </p>

        <p className='post-paragraph'>
            Now we need another information - what we learn (German) and this information has a title of the sentence object - <b><i>Ich lerne Deutsch. (I learn German.)</i></b> With the last information is the sentence more complete.</p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img1.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            How to give now more information to the interlocutor? - By making questions - How long? Where?. We will put both of these answers after the combination <b><i>Ich lerne Deutsch. (I learn German.)</i></b> in the sentence.
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img2.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            With the new information the sentence is more complex - <b><i>Ich lerne Deutsch seit zwei Jahren im Kurs. (I have been learning German for two years at the course.)</i></b> The important rule by making a sentence is that we should emphasize the order of the place and time indications. In the sentence with more indications first should be placed the time, and then the place indication.
        </p>

        <p>
            The sentence can be extended even more by answering the questions: With whom? and Why?. Where should we now order these answers? The solution is the TE-KA-MO-LO rule, which determines the following order of the sentence indications: time-reason-way-place.
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img3.png'} alt="bookshelf" />


        <p className='post-paragraph'>
            When we apply the TE-KA-MO-LO rule, the sentence sounds: <b><i>Ich lerne Deutsch seit zwei Jahren aus Spaß mit meinen Kollegen im Kurs. (I have been learning German for two years for fun with my colleagues on course.).</i></b>
        </p>

        <p className='post-paragraph'>
            The more information we want to give, the more complex the sentence structure is and the mentioned rule helps us by building it.
        </p>

        <p className='post-paragraph'>
            You can practice on your own by answering the following question: Was machen die Kinder im Park? (What are the children doing in the park?) Use the assotiogram:
        </p>

        <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/sentence-building/img4.png'} alt="bookshelf" />

        <p className='post-paragraph'>
            The sentence can sound this way: <b><i>Die Kinder spielen mit dem Hund im Park. (The children are playing with the dog in the park.)</i></b> Now you mention another information and order them right.
        </p>

        <p className='post-paragraph'>
            The purpose of this method is to learn how to order the sentence elements right according to the verb. First collect all the information you want to give and then begin with the simple combination subject-predicate-object. The TE-KA-MO-LO rule will help you by extending the sentence.
        </p>

        <p className='post-paragraph'>By practising and utilizing this method you will master sentence structure.</p>

    </div >)

}