import Flags from 'country-flag-icons/react/3x2';
import './FlagGroup.css';
import React from 'react';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useTranslation } from 'react-i18next'

export default function FlagGroup() {

    const { setLanguage } = React.useContext(LanguageContext)
    const { t, i18n } = useTranslation();

    function switchLanguage(arg) {
        setLanguage(arg);
        i18n.changeLanguage(arg.language);
    }

    return (<div className='flag-group'>
        <Flags.BA title="Bosnia and Herzegovina" className="flag" onClick={() => switchLanguage({ language: 'ba' })} />
        <Flags.DE title="Germany" className="flag" onClick={() => switchLanguage({ language: 'de' })} />
        <Flags.GB title="Great Britain" className="flag" onClick={() => switchLanguage({ language: 'en' })} />
    </div>)
}
