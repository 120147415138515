import categories from '../../../data/categories';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import './VocabularyInLanguageFamilies.css';

export default function PowerOfLanguageUse() {

    const { language } = React.useContext(LanguageContext)

    function getTitle() {
        if (language.language === 'ba') {
            return categories[1].ba.posts[0].title;
        } else if (language.language === 'de') {
            return categories[1].de.posts[0].title;
        } else {
            return categories[1].en.posts[0].title;
        }
    }

    function getBosnianContent() {
        return (<>
            <p className='post-paragraph'>
                Jedna od najvećih grešaka pri učenju stranih jezika je učenje vokabulara izolirano, napamet i van pripadajućeg konteksta. Zbog toga predstavljam jednu veoma uspješnu metodu učenja. Ona se sastoji iz toga da riječi učimo zajedno sa ostalim „članovima“ njihovih porodica. To znači pokušati zapamtiti što više riječi koji sadrže isti korijen. Primjere je najbolje potražiti u rječniku.
            </p>
            <p className='post-paragraph'>
                Kao primjer navodimo imenicu <span className='bold-italic-test'>Geduld (strpljenje)</span>. U rječniku se mogu pronaći sljedeće riječi sa ovim korijenom:
            </p>

            <img className='vocabulary-associogram' src={process.env.PUBLIC_URL + '/images/posts/vovaublary-in-language-families/de/associogram_1.png'} alt="vocabulary-family" />

            <p className='post-paragraph'>
                Za bolje pamćenje riječi je veoma važan naredni korak: Za svaku riječ navesti ćemo rečenični primjer, a najbolje neki interesantan, efektan i koji se najlakše može zapamtiti. Pokušajmo sa sljedećim primjerima:
            </p>

            <ul class='example-list'>
                <li><span className='orange-bolded-text'>die Geduld</span> (strpljenje, imenica) – „<span className='orange-bolded-text'>Geduld</span> ist schwierig, aber die Früchte sind kostbar.“  („Strpljenje je teško, ali su plodovi slatki.“)
                </li>
                <li><span className='orange-bolded-underlined-text'>geduld</span><span className='orange-bolded-text'>ig</span> (strpljiv, pridjev) – Ein <span className='orange-bolded-text'>geduldiger</span> Mensch ist ein starker Mensch. (Strpljiv čovjek je jak čovjek.)
                </li>
                <li><span className='orange-bolded-text'>sich</span> <span className='orange-bolded-underlined-text'>geduld</span><span className='orange-bolded-text'>en</span> (strpiti se, glagol) – In schwierigen Zeiten soll <span className='orange-bolded-text'>sich</span> der Mensch <span className='orange-bolded-text'>gedulden</span>. (U teškim vremenima se čovjek treba strpiti.)
                </li>
                <li><span className='orange-bolded-text'>die</span> <span className='orange-bolded-underlined-text'>Geduld</span><span className='orange-bolded-text'>sprobe</span> (test strpljivosti/izdržljivosti, imenica) - Die Vorbereitung für die schwierige Prüfung war eine große <span className='orange-bolded-text'>Geduldsprobe</span> für mich. (Priprema za teški ispit je bio pravi test strpljivosti/izdržljivosti za mene.)
                </li>
            </ul>
            <p className='post-paragraph'>
                Pokušajmo sada sa jednim glagolom, npr. glagolom <span className='bold-italic-test'>započeti</span>. <span className='bold-italic-test'>Započeti</span> je glagol koji nam daje podstrek za djelovanje, veoma je važan i često se koristi u njemačkom jeziku.
            </p>

            <p className='post-paragraph'>
                U rječniku se mogu pronaći imenice <span className='bold-italic-test'>der Anfang (početak)</span>, <span className='bold-italic-test'>der Anfänger (početnik)</span>, ženska varijanta – <span className='bold-italic-test'>die Anfängerin</span> (početnica), kao i množina – <span className='bold-italic-test'>die Anfänger (početnici).</span>. Isti korijen riječi sadrži i pridjev/prilog <span className='bold-italic-test'>anfänglich (početnički/na početku).</span>.
            </p>

            <img className='vocabulary-associogram' src={process.env.PUBLIC_URL + '/images/posts/vovaublary-in-language-families/de/associogram_2.png'} alt="associaogram" />

            <p className='post-paragraph'>
                Pogledajte sljedeće primjere i pokušajte ih zapamtiti:
            </p>

            <ul class='example-list'>
                <li><span className='red-bolded-underlined-text'>anfang</span><span className='red-bolded-text'>en</span> (započeti, glagol) – Nie ist es zu spät, um wieder<span className='red-bolded-text'>anzufangen</span>.(Nama je bliži prijevod: „Nikad nije kasno za novi početak.“)
                </li>
                <li><span className='red-bolded-text'>der Anfang</span> (početak, imenica) – „Jeder <span className='red-bolded-text'>Anfang</span> ist schwer.“ „Jeder Tag ist ein neuer Anfang.“  („Svaki početak je težak.“ „Svaki dan je novi početak.“)
                </li>
                <li><span className='red-bolded-underlined-text'>der Anfäng</span><span className='red-bolded-text'>er</span>, <span className='red-bolded-underlined-text'>die Anfäng</span><span className='red-bolded-text'>erin</span> (početnik, početnica - imenice) – „Jeder erfolgreiche Mensch war einmal ein <span className='red-bolded-text'>Anfänger</span>.“ („Svaki uspješan čovjek je nekad bio početnik.)
                </li>
                <li><span className='red-bolded-underlined-text'>anfäng</span><span className='red-bolded-text'>lich</span> (isprva, pridjev/prilog) - <span className='red-bolded-underlined-text'>Anfänglich</span> haben wir viel Hoffnung auf das neue Projekt gesetzt. (Isprva smo polagali veliku nadu u novi projekat.)
                </li>
            </ul>
            <p className='post-paragraph'>
                Izaberite i vi neku zanimljivu riječ i upoznajte se sa njenom porodicom pomoću navedene metode. Sretno!
            </p>
        </>);
    }

    function getGermanContent() {
        return (<>
            <p className='post-paragraph'>
                Einer der größten Fehler beim Sprachlernen ist das isolierte, auswendige Vokabellernen ohne zusammenhängenden Kontext. Deswegen stelle ich eine sehr erfolgreiche Lernmethode dar. Sie besteht darin, die Wörter zusammen mit anderen Mitgliedern ihrer Familie zu lernen. Das heißt, möglichst viele Wörter mit dem gleichen Wortstamm zu merken versuchen. Nach den Beispielen sollte man am besten in Wörterbüchern suchen.
            </p>
            <p className='post-paragraph'>
                Als Beispiel nehmen wir das Substantiv <span className='bold-italic-test'>Geduld</span>. Im Wörterbuch sind folgende Wörter mit dem Wortstamm <span className='bold-italic-test'>Geduld</span> zu finden:
            </p>

            <img className='vocabulary-associogram' src={process.env.PUBLIC_URL + '/images/posts/vovaublary-in-language-families/de/associogram_1.png'} alt="associaogram" />

            <p className='post-paragraph'>
                Für das bessere Einprägen von Wörtern im Gedächtnis wäre der nächste Schritt sehr wichtig – für jedes Wort wird ein Beispielsatz genannt, am besten ein interessanter, effektiver und leicht merkbarer Satz. Versuchen wir es mit folgenden Beispielen:
            </p>

            <ul class='example-list'>
                <li><span className='orange-bolded-text'>die Geduld</span> (Substantiv) – „<span className='orange-bolded-text'>Geduld</span> ist schwierig, aber die Früchte sind kostbar.“</li>
                <li><span className='orange-bolded-underlined-text'>geduld</span><span className='orange-bolded-text'>ig</span> (Adjektiv) – Ein <span className='orange-bolded-text'>geduldiger</span> Mensch ist ein starker Mensch.</li>
                <li><span className='orange-bolded-text'>sich</span> <span className='orange-bolded-underlined-text'>geduld</span><span className='orange-bolded-text'>en</span> (Verb) – In schwierigen Zeiten soll <span className='orange-bolded-text'>sich</span> der Mensch <span className='orange-bolded-text'>gedulden</span>.</li>
                <li><span className='orange-bolded-text'>die</span> <span className='orange-bolded-underlined-text'>Geduld</span><span className='orange-bolded-text'>sprobe</span> (Substantiv) - Die Vorbereitung für die schwierige Prüfung war eine große <span className='orange-bolded-text'>Geduldsprobe</span> für mich.</li>
            </ul>
            <p className='post-paragraph'>
                Jetzt versuchen wir mit einem  Verb, z.B. dem Verb <span className='bold-italic-test'>anfangen</span>. <span className='bold-italic-test'>Anfangen</span> ist ein Verb, das uns zur Aktion motiviert, ist sehr wichtig und wird oft im Deutschen benutzt.
            </p>

            <p className='post-paragraph'>
                Im Wörterbuch sind Substantive <span className='bold-italic-test'>der Anfang</span>, <span className='bold-italic-test'>der Anfänger</span> und die weibliche Variante, <span className='bold-italic-test'>die Anfängerin</span>, sowie der Plural – <span className='bold-italic-test'>die Anfänger</span> zu finden. Den gleichen Wortstamm enthält auch das Adjektiv/Adverb <span className='bold-italic-test'>anfänglich</span>.
            </p>

            <img className='vocabulary-associogram' src={process.env.PUBLIC_URL + '/images/posts/vovaublary-in-language-families/de/associogram_2.png'} alt="associaogram" />

            <p className='post-paragraph'>
                Schauen Sie auf folgende Beispielsätze an und versuchen Sie, sie sich zu merken:
            </p>

            <ul class='example-list'>
                <li><span className='red-bolded-underlined-text'>anfang</span><span className='red-bolded-text'>en</span> (Verb) – Nie ist es zu spät, um wieder<span className='red-bolded-text'>anzufangen</span>.</li>
                <li><span className='red-bolded-text'>der Anfang</span> (Substantiv) – „Jeder <span className='red-bolded-text'>Anfang</span> ist schwer.“ „Jeder Tag ist ein neuer Anfang.“</li>
                <li><span className='red-bolded-underlined-text'>der Anfäng</span><span className='red-bolded-text'>er</span>, <span className='red-bolded-underlined-text'>die Anfäng</span><span className='red-bolded-text'>erin</span> (Substantive) – „Jeder erfolgreiche Mensch war einmal ein <span className='red-bolded-text'>Anfänger</span>.“</li>
                <li><span className='red-bolded-underlined-text'>anfäng</span><span className='red-bolded-text'>lich</span> (Adjektiv/Adverb) - <span className='red-bolded-underlined-text'>Anfänglich</span> haben wir viel Hoffnung auf das neue Projekt gesetzt.</li>
            </ul>
            <p className='post-paragraph'>
                Wählen Sie ein interessantes Wort und lernen Sie seine Familienmitglieder mithilfe von der beschriebenen Methode kennen. Viel Glück!
            </p>
        </>);
    }

    function getEnglishContent() {
        return (<>
            <p className='post-paragraph'>
                One of the biggest mistakes in the process of language learning is isolated vocabulary learning by heart and without common context. Therefore I will introduce a very successful learning method. It consists of learning words in their word families. This way of learning means trying to remember as many words with the same root as possible. It is best to look up the examples in the dictionary.
            </p>
            <p className='post-paragraph'>
                We can choose the noun <span className='bold-italic-test'>Geduld (patience)</span> as an example. There are following words with this root in the dictionary:
            </p>

            <img className='vocabulary-associogram' src={process.env.PUBLIC_URL + '/images/posts/vovaublary-in-language-families/de/associogram_1.png'} alt="associaogram" />

            <p className='post-paragraph'>
                For better memorization the next step is also important: For each of the words we are going to provide a sentence as an example and it is always good to choose an interesting, effective example which is easy to memorize. We can try with following examples:
            </p>

            <ul class='example-list'>
                <li><span className='orange-bolded-text'>die Geduld</span> (patience, noun) – „<span className='orange-bolded-text'>Geduld</span> ist schwierig, aber die Früchte sind kostbar.“ („Patience is hard, but it's fruits are sweet.“)
                </li>
                <li><span className='orange-bolded-underlined-text'>geduld</span><span className='orange-bolded-text'>ig</span> (patient, adjective) – Ein <span className='orange-bolded-text'>geduldiger</span> Mensch ist ein starker Mensch. (A patient person is a strong person.)
                </li>
                <li><span className='orange-bolded-text'>sich</span> <span className='orange-bolded-underlined-text'>geduld</span><span className='orange-bolded-text'>en</span> (to be patient, verb) – In schwierigen Zeiten soll <span className='orange-bolded-text'>sich</span> der Mensch <span className='orange-bolded-text'>gedulden</span>. (One should be patient in hard times.)
                </li>
                <li><span className='orange-bolded-text'>die</span> <span className='orange-bolded-underlined-text'>Geduld</span><span className='orange-bolded-text'>sprobe</span> (test of patience, noun) - Die Vorbereitung für die schwierige Prüfung war eine große <span className='orange-bolded-text'>Geduldsprobe</span> für mich. (Preparation for the hard exam was a real test of patience for me.)
                </li>
            </ul>
            <p className='post-paragraph'>
                Now we can try with one of the verbs, for example with a verb <span className='bold-italic-test'>anfangen (to begin)</span>. <span className='bold-italic-test'>Anfangen (to begin)</span> is a verb that motivates us to be active, it is a very important and often used verb in German language.
            </p>

            <p className='post-paragraph'>
                In the dictionary the following nouns can be found: <span className='bold-italic-test'>der Anfang (beginning)</span>, <span className='bold-italic-test'>der Anfänger (beginner)</span>, the female form – <span className='bold-italic-test'>die Anfängerin</span> (in English: beginner like male form) and also plural form – <span className='bold-italic-test'>die Anfänger (beginners)</span>. The same word root also contains an adjective/adverb <span className='bold-italic-test'>anfänglich (at first/initial)</span>.
            </p>

            <img className='vocabulary-associogram' src={process.env.PUBLIC_URL + '/images/posts/vovaublary-in-language-families/de/associogram_2.png'} alt="associaogram" />

            <p className='post-paragraph'>
                Pay attention to the following examples and try to remember them:
            </p>

            <ul class='example-list'>
                <li><span className='red-bolded-underlined-text'>anfang</span><span className='red-bolded-text'>en</span> (to begin, verb) – Nie ist es zu spät, um wieder<span className='red-bolded-text'>anzufangen</span>. (It is never too late to start again.)</li>
                <li><span className='red-bolded-text'>der Anfang</span> (beginning, noun) – „Jeder <span className='red-bolded-text'>Anfang</span> ist schwer.“ „Jeder Tag ist ein neuer Anfang.“ („Every beginning is hard.“ „Every day is a new beginning.“)
                </li>
                <li><span className='red-bolded-underlined-text'>der Anfäng</span><span className='red-bolded-text'>er</span>, <span className='red-bolded-underlined-text'>die Anfäng</span><span className='red-bolded-text'>erin</span> (beginner, nouns) – „Jeder erfolgreiche Mensch war einmal ein <span className='red-bolded-text'>Anfänger</span>.“ („Every successful person was once a beginner.“)
                </li>
                <li><span className='red-bolded-underlined-text'>anfäng</span><span className='red-bolded-text'>lich</span> (at first/initial; adjective/adverb) - <span className='red-bolded-underlined-text'>Anfänglich</span> haben wir viel Hoffnung auf das neue Projekt gesetzt. (At first we pinned a great hope on the new project.)
                </li>
            </ul>
            <p className='post-paragraph'>
                Wählen Sie ein interessantes Wort und lernen Sie seine Familienmitglieder mithilfe von der beschriebenen Methode kennen. Viel Glück!
            </p>
        </>);
    }

    function getContent() {
        if (language.language === 'ba') {
            return getBosnianContent();
        } else if (language.language === 'de') {
            return getGermanContent();
        } else {
            return getEnglishContent();
        }
    }

    return (<div class='post-body'>
        <h1 className='post-title'>{getTitle()}</h1>
        {getContent()}
    </div>)
}