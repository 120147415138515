import React from 'react';
import Header from '../Header';
import Footer from '../Footer/Footer';
import { Outlet } from 'react-router-dom';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Layout(properties) {

    const [language, setLanguage] = React.useState({ language: "ba" })


    return (
        <LanguageContext.Provider value={{ language: language, setLanguage: setLanguage }}>
            <div className='container custom-container'>
                <header>
                    <Header />
                </header>
                <main>
                    <Outlet />
                </main>
                <footer>
                    <Footer />
                </footer>
            </div>

        </LanguageContext.Provider >
    )
}