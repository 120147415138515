import categories from '../../../data/categories';
import { LanguageContext } from '../../../contexts/LanguageContext';
import React from 'react';
import './SimilarWords.css';

export default function SimilarWords() {
    const { language } = React.useContext(LanguageContext)

    function getTitle() {
        if (language.language === 'ba') {
            return categories[2].ba.posts[0].title;
        } else if (language.language === 'de') {
            return categories[2].de.posts[0].title;
        } else {
            return categories[2].en.posts[0].title;
        }
    }

    function getBosnianContent() {
        return (<>
            <p className='post-paragraph'>
                Opštepoznato je da njemački i engleski jezik vode porijeklo iz istog jezika. Način razvoja historije jezika umnogome olakšava učenje jezika, jer postoje evidentne sličnosti u izrazima u njemačkom i engleskom. One dolaze do izražaja kod pojedinih jednostavnih glagola. U nastavku slijede neki od primjera:</p>
            <img className='similar-words-associogram' src={process.env.PUBLIC_URL + '/images/posts/similar-words/similar-words.png'} alt="german-flag" />
            <p className='post-paragraph'>
                Značenje riječi upućuje na osnovne ljudske potrebe poput jela, pića i kretanja. U te potrebe se pak ubraja i potreba za učenjem koje čini preduslov za razvoj čovječanstva. Prve Božje riječi upućene ljudima su glasile: „Uči, čitaj...!“ Poveznice između jezika se zasnivaju na zajedničkim fundamentalnim istinama i na tokovima razvoja historije i jezika, te takvo saznanje uveliko koristi prilikom pronalaženja jezičkih paralela i usvajanja stranih izraza.</p>
        </>)
    }

    function getGermanContent() {
        return (<>
            <p className='post-paragraph'>
                Bekannt ist die Tatsache, dass Englisch und Deutsch Ursprung in der gleichen Sprache haben. Die Entwicklung der Sprachgeschichte erleichtert das Sprachenlernen, weil viele Ähnlichkeiten bei den Ausdrücken im Englischen und Deutschen bestehen. Diese kommen bei einigen einfachen Verben zum Ausdruck. Im Folgenden werden einige Beispiele gegeben:</p>
            <img className='similar-words-associogram' src={process.env.PUBLIC_URL + '/images/posts/similar-words/similar-words.png'} alt="german-flag" />
            <p className='post-paragraph'>
                Die Verbbedeutungen weisen auf die existentiellen menschlichen Bedürfnisse (essen, trinken, gehen) hin. Dazu zählt aber auch der Bedarf nach dem Lernen, weil es die Voraussetzung für die Entwicklung der Menschheit darstellt. Die ersten Worte Gottes, die an die Menschen gerichtet wurden, lauten: „Lerne, Lese...!“. Die Sprachverbindung beruht auf der gemeinsamen wesentlichen Wahrheit und dem Lauf der Geschichten- und Sprachentwicklung und diese Erkenntnis wird bei dem Sprachvergleich und dem Aneignen der fremden Ausdrücken nützlich.</p>
        </>)
    }

    function getEnglishContent() {
        return (<>
            <p className='post-paragraph'>
                It's a well-known fact that German and English are languages of the same origin. The way that language history was developing makes language learning easier, because there are many similarities between terms in German and English. They are reflected in form of some simple verbs, showed in the following examples:</p>
            <img className='similar-words-associogram' src={process.env.PUBLIC_URL + '/images/posts/similar-words/similar-words.png'} alt="german-flag" />
            <p className='post-paragraph'>
                The verbs express the essential human needs (eat, drink, go). They also include the need for learning, because it's a basis for the development of mankind. The first God's words, that were directed toward human beings, are: “Learn, read…!”. The connection between languages is based on the common fundamental truth and on the flow of the history and language development, and that recognition should be used for comparing languages and learning foreign terms.</p>
        </>);
    }

    function getContent() {
        if (language.language === 'ba') {
            return getBosnianContent();
        } else if (language.language === 'de') {
            return getGermanContent();
        } else {
            return getEnglishContent();
        }
    }

    return (<div class='post-body'>
        <h1 className='post-title'>{getTitle()}</h1>
        {getContent()}
    </div>)
}