import HumanUnderstandingBa from "./HumanUnderstandingBa";
import HumanUnderstandingDe from "./HumanUnderstandingDe";
import HumanUnderstandingEn from "./HumanUnderstandingEn";
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import './HumanUnderstanding.css';

export default function HumanUnderstanding() {

    const { language } = React.useContext(LanguageContext)

    if (language.language === 'ba') {
        return HumanUnderstandingBa()
    } else if (language.language === 'de') {
        return HumanUnderstandingDe()
    } else {
        return HumanUnderstandingEn()
    }

}