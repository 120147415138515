const categories = [{
    id: 1,
    de: {
        title: "Die Macht des Sprachgebrauchs",
        abstract: "„Alles, was wir um uns herum sehen, ist vom Gottes Wort „Sei!.“ entstanden.“ Die Macht eines Wortes liegt darin, dass es bildet, verbessert und schöner macht. Finden wir die Inspiration für das Fremdsprachenlernen im Weltschönermachen mit dem Wortgebrauch.",
        posts: [{
            id: 1,
            title: "Sprachlernen und Selbsterkenntnis",
            abstract: "Die Klarheit des Ausdrucks in der Sprache, die wir beherrschen, äußert sich als Ergebnis der Sprachkenntnisse, aber auch als Produkt der formierten Gedanken, die wir zum Ausdruck bringen. Die Kommunikationsfähigkeit hängt von der Stufe der linguistischen Kenntnisse ab, aber die Qualität unserer Wahrnehmung und der Welterkenntnis stellt die Grundlage für die Ausdrucksfähigkeit dar.",
            imageUrl: "/images/posts/power-of-language/bookshelf.png"
        },
        {
            id: 2,
            title: "Sprache als Mittel zum Menschenverständnis",
            abstract: "Worte als Kommunikationsmittel haben großen Wert und spielen die wesentliche Rolle in den Beziehungen mit anderen Menschen. Allerdings, nur die Kommunikation, die auf der echten Überzeugung vom respektvollen Umgang mit anderen, dem Verständnis und dem taktvollen Zugang gegründet wird, wird feste Beziehungen bewirken.",
            imageUrl: "/images/posts/human-understanding/sokrat.webp"
        },
        {
            id: 3,
            title: "Gutes Wort",
            abstract: "Unsere Gedanken, Worte und Taten erstellen unsere Mikrowelt, die auf die Welt des anderen Einfluss ausübt. Darum ist es wesentlich wichtig, die Dialogkultur durch schöne Worte, ehrliche Komplimente, wohlgemeinte Ratschläge, zu pflegen.",
            imageUrl: "/images/posts/kind-words/kind-words.webp"
        }
        ]
    },
    en: {
        title: "The Power of Language Use",
        abstract: "“Everything around us was created by God's word: “Be!”.”  The power of word is to create, fix and make things beautiful. Let's find inspiration for foreign language learning in the purpose of making the world better by using words.",
        posts: [{
            id: 1,
            title: "Language Learning and Self-understanding",
            abstract: "The clarity of an expression in the spoken language manifests itself as a result of language knowledge, but also as a product of the formation of the thought of what we want to express. The communication skill depends on the linguistic knowledge level, but the quality of our perception and understanding of the world makes a basis for the art of expression.",
            imageUrl: "/images/posts/power-of-language/bookshelf.png"
        },
        {
            id: 2,
            title: "Language as a medium for human understanding",
            abstract: "Words as a communication medium have their value and essential role in building relations with other people. But only communication based on the true belief of respecting others, understanding and tactful approach can result in strong relations with others.",
            imageUrl: "/images/posts/human-understanding/sokrat.webp"
        },
        {
            id: 3,
            title: "Good words",
            abstract: "Our thoughts, words and acts create our micro world and it influences the world of others. Because of that, it is crucial to cultivate a culture of dialogue through good words, an honest compliment, and well-intentioned advice.",
            imageUrl: "/images/posts/kind-words/kind-words.webp"
        }
        ]
    },
    ba: {
        title: "Moć upotrebe jezika",
        abstract: "“Sve što vidimo oko nas postalo je uz riječ, Božiju riječ: “Budi!“.“ Moć riječi leži u tome da gradi, popravlja i uljepšava. Pronađimo inspiraciju za učenje stranih jezika u činjenju svijeta ljepšim uz upotrebu riječi.",
        posts: [{
            id: 1,
            title: "Učenje jezika i proces samospoznaje",
            abstract: "Jasnoća našeg izraza na jeziku koji govorimo se manifestuje kao rezultat poznavanja datog jezika, ali i kao plod misaone zaokruženosti onoga što želimo izraziti. Vještina komunikacije zavisi od stepena lingvističkog znanja, ali kvalitet naše percepcije i spoznaje svijeta predstavlja temelj umijeća izražavanja.",
            imageUrl: "/images/posts/power-of-language/bookshelf.png"
        },
        {
            id: 2,
            title: "Jezik kao sredstvo međuljudskog razumijevanja",
            abstract: "Riječi kao komunikacijsko sredstvo imaju svoju težinu i ključnu ulogu u izgradnji odnosa sa ljudima. Međutim, samo komunikacija temeljena na istinskom uvjerenju o poštovanju drugoga, razumijevanju i taktičnom pristupu može uroditi kvalitetnim odnosima s drugima.",
            imageUrl: "/images/posts/human-understanding/sokrat.webp"
        },
        {
            id: 3,
            title: "Lijepa riječ",
            abstract: "Naše misli, riječi i postupci kreiraju naš mikrosvijet, a on utiče na svijet drugoga. Zbog toga je suštinski važno njegovati kulturu komuniciranja kroz lijepu riječ, iskren kompliment, dobronamjeran savjet.",
            imageUrl: "/images/posts/kind-words/kind-words.webp"
        }
        ]
    },
    title: "Data processing",
    abstract: "Data processing can have mutliple forms...",
    imageUrl: "/images/hands_in_water.jpg"
}, {
    id: 2,
    de: {
        title: "Tipps zum Sprachenlernen",
        abstract: "Lernen wir Fremdsprachen, aber wie? Gehen wir zusammen durch ausprobierte und wirksame Methoden zum Vokabellernen, zur Grammatik- und Phonetikbeherrschung und zur flüssigen Kommunikation auf der Fremdsprache. Lernen wir Sprachfertigkeiten trainieren – Lesen, Hören, Schreiben und Sprechen.",
        posts: [{
            id: 1,
            title: "Vokabeln in ihren Wortfamilien lernen",
            abstract: "Die erste Regel beim Vokabellernen ist, dass man die Wörter nicht isoliert und auswendig lernen sollte. In diesem Text stellen wir eine einfache, aber wirksame Methode für das Vokabellernen als Teil der Wortfamilien dar.",
            imageUrl: "/images/categories/family.webp"
        },
        {
            id: 2,
            title: "Satzbildung: Verb-König-Methode",
            abstract: "Viele Deutschlerner haben Probleme mit dem Satzbau, weil sie unsicher sind, womit der Satz beginnt oder nicht wissen, wie Satzelemente richtig geordnet werden sollen. Dabei kann meine selbstgenannte Verb-König-Methode helfen.",
            imageUrl: "/images/posts/sentence-building/crown.jpg"
        },
        {
            id: 3,
            title: "Substantivartikel visuell und als eine Einheit lernen",
            abstract: "Der, die oder das? Üben Sie den Gebrauch von einer ausgezeichneten Methode zum Lernen und Merken von Substantivartikeln!.",
            imageUrl: "/images/posts/noun-articles/dinner-room.webp"
        },
        {
            id: 4,
            title: "Sprechen (und Schreiben!) auf Deutsch mithilfe von Monologen üben",
            abstract: "Beim Fremdsprachenlernen kann unser Fortschritt im flüssigen Sprechen dadurch verzögert werden, dass wiraußer des Unterrichts keinen Gesprächspartner haben. Die Lösung liegt in einer ausgezeichneten Methode, mit der wir eigenständig und alltäglich die mündliche Kommunikation trainieren können. Schau dir die Textbeispiele an und wende diese Methode gleich an.",
            imageUrl: "/images/posts/exercising-language-skills/cover.webp"
        }
        ]
    },
    en: {
        title: "Tips for Language Learning",
        abstract: "How to learn foreign languages? Let´s go together through already used and efficient methods for vocabulary acquisition, mastering of grammar and phonetic and fluent communication in foreign language. We learn how to train language skills – reading, listening, writing and speaking.",
        posts: [{
            id: 1,
            title: "Vocabulary Learning in Word Families",
            abstract: "The first rule for vocabulary learning should be not to learn words in isolation and by heart. In this text we are going to present a simple, but efficient method for learning words as a part of their word families.",
            imageUrl: "/images/categories/family.webp"
        },
        {
            id: 2,
            title: "Sentence formation: Verb-King-Method",
            abstract: "Many German learners have issues with understanding how  sentence building works, because they may be not sure how to begin the sentence right or what's the right order of sentence parts. In that case my self-named king-verb-method can help.",
            imageUrl: "/images/posts/sentence-building/crown.jpg"
        },
        {
            id: 3,
            title: "Learning the noun articles using the method of clustering and visualizing",
            abstract: "Der, die, or das? Practice the use of an excellent method for learning and remembering noun genders!",
            imageUrl: "/images/posts/noun-articles/dinner-room.webp"
        },
        {
            id: 4,
            title: "Exercising the Skill of Speaking (and Writing!) in German by Creating Monologues",
            abstract: "When learning a foreign language, our progress in speaking fluently can be delayed by not having a conversation partner outside of class. The solution lies in an excellent method through which we can independently and regularly practice oral communication. Take a look at the text examples and apply this method right away.",
            imageUrl: "/images/posts/exercising-language-skills/cover.webp"
        }
        ]
    },
    ba: {
        title: "Savjeti za učenje jezika",
        abstract: "Učimo strane jezike, ali kako? Prođimo zajedno kroz isprobane i djelotvorne metode za usvajanje vokabulara, savladavanje gramatike, fonetike, uspostavljanje tečne komunikacije na stranom jeziku. Naučimo kako trenirati jezičke vještine – čitanje, slušanje, pisanje i govor.",
        posts: [{
            id: 1,
            title: "Učenje vokabulara u sklopu porodica riječi",
            abstract: "Prvo pravilo pri usvajanju vokabulara je ne učiti riječi pojedinačno i napamet. U ovom tekstu ćemo predstaviti jednostavnu, a efikasnu metodu učenja riječi u sklopu njihovih porodica.",
            imageUrl: "/images/categories/family.webp"
        },
        {
            id: 2,
            title: "Tvorba rečenice: Kraljevsko-glagolska metoda",
            abstract: "Mnogi polaznici koji uče njemački jezik imaju problem sa tvorbom rečenice, jer su nesigurni kako je započeti ili kako ispravno poredati rečenične dijelove. U tom slučaju može biti od pomoći moja takozvana kraljevsko-glagolska metoda.",
            imageUrl: "/images/posts/sentence-building/crown.jpg"
        },
        {
            id: 3,
            title: "Učenje rodova imenica metodom grupisanja i vizualizacije",
            abstract: "Der, die ili das? Uvježbajte upotrebu odlične metode za učenje i pamćenje rodova imenica!",
            imageUrl: "/images/posts/noun-articles/dinner-room.webp"
        },
        {
            id: 4,
            title: "Uvježbavanje vještine govora (i pisanja!) na njemačkom jeziku uz pomoć monologa",
            abstract: "Kod učenja stranog jezika naš napredak u tečnijem govoru može da usporava nedostatak sagovornika van nastave. Rješenje je odlična metoda pomoću koje možemo samostalno i svakodnevno trenirati usmenu konverzaciju. Pogledaj primjere u tekstu i primijeni ovu metodu već danas.",
            imageUrl: "/images/posts/exercising-language-skills/cover.webp"
        }
        ]
    },
    title: "Data collection",
    abstract: "Data collection can manifest ifself in different ways...",
    imageUrl: "/images/german_flag.jpg"
}, {
    id: 3,
    de: {
        title: "Deutsch und andere Sprachen",
        abstract: "Lernen wir Sprachen mithilfe von anderen Sprachen! Fremdsprachen, die aus denselben Sprachfamilien stammen, enthalten zahlreiche Ähnlichkeiten bei Sprachphänomenen. Diese helfen uns vor allem beim Aneignen von fremden Ausdrücken. Außerdem eröffnet die Entdeckung von Ähnlichkeiten und Unterschieden zwischen den Fremdsprachen viele Türen der Kulturforschung.",
        posts: [{
            id: 1,
            title: "Ähnliche Verben im Deutschen und Englischen",
            abstract: "Ähnliche Begriffe in verschiedenen Sprachen erleichtern uns ihr Merken sehr. Die Nähe zwischen dem Englischen und Deutschen sollte man besonders als Erleichterung beim Vokabelaneignen nutzen. Lass uns sehen, welche Verben sehr ähnlich auf diesen zwei Sprachen lauten.",
            imageUrl: "/images/posts/similar-words/german-british.png"
        }
        ]
    },
    en: {
        title: "German and Other Languages",
        abstract: "Let's learn languages using other languages! Foreign languages that are a part of the same language families have many similarities in language phenomena. That is what helps us the most in acquiring foreign terms. Besides that, finding similarities and differences between foreign languages opens many fields for culture research.",
        posts: [{
            id: 1,
            title: "Similar verbs in German and English",
            abstract: "Similar terms in different languages make their memorization much easier. The cognition of English and German language should be used as a benefit for acquiring vocabulary. Let's read which verbs sound very similar in these two languages.",
            imageUrl: "/images/posts/similar-words/german-british.png"
        }
        ]
    },
    ba: {
        title: "Njemački i drugi jezici",
        abstract: "Učimo jezike pomoću drugih jezika! Strani jezici koji potiču iz istih jezičkih porodica sadrže brojne sličnosti kod jezičkih fenomena. One nam pomažu najviše pri usvajanju stranih izraza. A pronalaženje sličnosti i razlika među stranim jezicima otvara i mnoga polja kulturološkog istraživanja.",
        posts: [{
            id: 1,
            title: "Sličnost glagola u njemačkom i engleskom",
            abstract: "Slični pojmovi u različitim jezicima nam uveliko olakšavaju pamćenje istih. Naročito bliskost engleskog i njemačkog jezika treba koristiti kao olakšicu pri usvajanju vokabulara. Pročitajmo koji to glagoli glase veoma slično na ova dva jezika.",
            imageUrl: "/images/posts/similar-words/german-british.png"
        }
        ]
    },
    title: "Property based testing",
    abstract: "Initially it was used for...",
    imageUrl: "/images/language_arrows.jpg"
}, {
    id: 4,
    de: {
        title: "Sprache und Kultur",
        abstract: "Der Reiz beim Fremdsprachenlernen liegt in der Erforschung verschiedener Kulturen. Und wie kann man am besten eine andere Kultur kennenlernen, wenn nicht durch eine Reise! Sei es real oder fiktiv, durch endlose Schöpfungen der Literatur wandernd, auf dem Weg nach dem Verschiedenen sind wir auf der Suche nach der Selbstentdeckung.",
        posts: [{
            id: 1,
            title: "Prinzip der Pünktlichkeit in der deutschen Kultur",
            abstract: "Zeit ist ein Geschenk, mit dem wir unterschiedlich umgehen. Das Zeitbewusstsein und der Zeitwert sind Teil der kulturellen Denkweise. Schauen wir anhand Ihres Alltags, inwieweit die Deutschen mit dem Zeitphänomen umgehen.",
            imageUrl: "/images/posts/gift-of-time/clock.png"
        },
        {
            id: 2,
            title: "Das Ideal der Güte im Roman “der Idiot”, von F.M.Dostojewski",
            abstract: "Welche Rolle hat ein edler Einzelner in der heutigen Gesellschaft? Stellt die Güte ein selbstzerstörendes Ideal dar, oder kann ihre Schönheit trotzdem die Welt retten? Lesen Sie mehr davon in der Interpretation des Meisterwerks der russischen Literatur.",
            imageUrl: "/images/posts/ideal-of-goodness/image.webp"
        }
        ]
    },
    en: {
        title: "Language and Culture",
        abstract: "The joy of foreign language learning is in finding out about the other cultures. And is there any better way of getting to know another culture like traveling? Whether it is real or fictional, by wandering through endless creation of literature to experience something different, we are trying to find ourselves.",
        posts: [{
            id: 1,
            title: "Principle of punctuality in German culture",
            abstract: "Time is a gift that we deal with differently. The awareness and the value of time is also a part of the cultural state of mind. Let's see how German people relate to a phenomenon of time based on their daily routine.",
            imageUrl: "/images/posts/gift-of-time/clock.png"
        },
        {
            id: 2,
            title: "Ideal of goodness in the novel “Idiot” from F.M.Dostoyevsky",
            abstract: "Which place has a noble individual in the society of today? Is goodness an self-destructive ideal or can its beauty still save the world? Read more of it in the interpretation of the masterpiece of Russian literature.",
            imageUrl: "/images/posts/ideal-of-goodness/image.webp"
        }
        ]
    },
    ba: {
        title: "Jezik i kultura",
        abstract: "Čar izučavanja stranih jezika se nalazi u istraživanju različitih kultura. A kako bolje upoznati drugu kulturu nego kroz putovanje! Bilo realno ili fiktivno, šećući se beskrajem književnog stvaralaštva, na putu ka drugačijem tragamo za otkrivanjem sebe.",
        posts: [{
            id: 1,
            title: "Princip vremenske preciznosti u njemačkoj kulturi",
            abstract: "Vrijeme je dar prema kojem se odnosimo na individualan način. Svijest o vremenu i njegovo vrednovanje je i dio kulturološkog mentaliteta.  Da vidimo na koji način se Nijemci odnose prema fenomenu vremena na osnovu njihove svakodnevnice...",
            imageUrl: "/images/posts/gift-of-time/clock.png"
        },
        {
            id: 2,
            title: "Ideal dobrote u djelu “Idiot”, Fjodora M. Dostojevskog",
            abstract: "Koje je mjesto plemenitom pojedincu u današnjem društvu? Da li je dobrota samodestruktivni ideal ili ljepota dobra ipak može spasiti svijet? Čitajte u interpretaciji remek-djela ruske književnosti.",
            imageUrl: "/images/posts/ideal-of-goodness/image.webp"
        }
        ]
    },
    title: "Verifying customer requirements",
    abstract: "In order to asure that...",
    imageUrl: "/images/houses.jpg"
}]

export default categories;
