import './App.css';
import Quote from './components/Quote/Quote';
import CategoriesOverview from './components/CategoriesOverview/CategoriesOverview'
import React from 'react';
import { LanguageContext } from './contexts/LanguageContext';

function App() {

  const { language } = React.useContext(LanguageContext)

  function getText() {
    if (language.language === 'de') {
      return "“Das Wort als Mittel zum Dialog, zur Kommunikation und Kultur stellt die Höchstleistung der Menschheit in Kreativität und Schöpfung dar. Nie wird man eine Maschine zur Erzeugung des literarischen Werks erfinden können. Nie wird die Technik zum Konkurrent des Menschen bei der Wortschöpfung.”";
    } else if (language.language === 'en') {
      return "“Words as a means of dialog, communication, culture are the highest level of human range in creativity and creation. No one will ever be able to make a machine which will create a piece of literary work. Technology will never become a competitor to mankind in creation of a word.”";
    } else {
      return "“Riječ kao sredstvo dijaloga, komunikacije, kulture je najviši stepen ljudskog dometa u kreativnosti i stvaralaštvu. Nikada se neće desiti da napravite mašinu koja će napraviti književno djelo. Nikada se neće desiti da tehnika postane konkurent čovjeku u kreaciji riječi.”";
    }
  }

  function getAuthor() {
    if (language.language === 'de') {
      return "Akademiker Dr. Muamer Zukorlić Mufti";
    } else if (language.language === 'en') {
      return "Academic Dr. Muamer Zukorlic Mufti";
    } else {
      return "Akademik dr. Muamer Zukorlić Muftija";
    }
  }

  return (
    <>
      <Quote text={getText()} author={getAuthor()} />
      <CategoriesOverview />
    </>
  );
}

export default App;
