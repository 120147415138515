import NounArticlesBa from "./NounArticlesBa";
import NounArticlesDe from "./NounArticlesDe";
import NounArticlesEn from "./NounArticlesEn";
import './NounArticles.css';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

export default function NounArticles() {

  const { language } = React.useContext(LanguageContext)

  if (language.language === 'ba') {
    return NounArticlesBa()
  } else if (language.language === 'de') {
    return NounArticlesDe()
  } else {
    return NounArticlesEn()
  }

}
