import ExercisingLanguageSkillsBa from "./ExercisingLanguageSkillsBa";
import ExercisingLanguageSkillsDe from "./ExercisingLanguageSkillsDe";
import ExercisingLanguageSkillsEn from "./ExercisingLanguageSkillsEn";
import './ExercisingLanguageSkills.css';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

export default function ExercisingLanguageSkills() {

  const { language } = React.useContext(LanguageContext)

  if (language.language === 'ba') {
    return ExercisingLanguageSkillsBa()
  } else if (language.language === 'de') {
    return ExercisingLanguageSkillsDe()
  } else {
    return ExercisingLanguageSkillsEn()
  }

}