export default function KindWordsDe() {

    return (<div class='post-body'>
        <h1 className='post-title'>Gutes Wort</h1>

        <p className='post-paragraph-centered'>
            <i>
                „Siehst du nicht, wie Allah ein Gleichnis von einem guten Wort geprägt hat? Es ist wie ein guter Baum, dessen Wurzeln fest sitzen und dessen Zweige in den Himmel reichen. Er bringt seinen Ernteertrag zu jeder Zeit – mit der Erlaubnis seines Herrn. "Und Allah prägt für die Menschen Gleichnisse, auf die sie bedenken mögen.“
            </i>
        </p>

        <p className='post-paragraph-centered'>
            (Ibrahim 24:25)
        </p>

        <p className='post-paragraph'>
            Der Wert eines schönen Wortes basiert auf dem religiösen, bzw. dem universalen Humanitätsprinzip. Tatsächlich herrscht zwischen den Menschen eine Unerträglichkeit, die die Welt zur Zerstörung führt, und die Ursache dafür ist die Schlacke der schlechten Gedanken gegenüber anderen Menschen und insbesondere gegenüber denjenigen, die sich von uns unterscheiden. Ein schlechter Gedanke führt zu einem schlechten Wort, das schlechte Wort zu einer schlechten Tat und schließlich zum schlechten Verhältnis. Um imstande zu sein, ein schönes Wort zu sagen, müssen wir vor allem unsere Herzen von schlechten Gedanken und Eigenschaften reinigen. Die Abrechnung mit sich selbst und die Beschönigung des eigenen Charakters ist die Pflicht jedes Einzelnen, der eine bedeutsame Rolle in der Welt spielt. Unsere Gedanken, Worte und Taten erstellen unsere Mikrowelt, die auf die Welt des anderen Einfluss ausübt. Darum ist es wesentlich wichtig, die Dialogkultur durch schöne Worte, ehrliche Komplimente, wohlgemeinte Ratschläge, zu pflegen. Schöne Worte erwärmen die Herzen, mildern die Trauer und öffnen die Türen des Guten überall.</p>

        <p className='post-paragraph'>
            Die Schönheit eines Ausdrucks hängt doch von der persönlichen Bewusstseinsebene, der Sichtweise, von der Breite des Vokabelspektrums und der anderen Sprachfähigkeiten. Sie stellt das Vermögen eines formierten Wesens und nicht nur eine formell gelernte Aussage dar. Im Hintergrund eines schönen Wortes muss ehrliche Absicht stehen und nur als solches kann das Wort einen anderen erreichen. Die Wurzel eines schönen Wortes ist ein reines Herz, dessen Zweige schöne Worte und ehrliche Taten seien, und die Erkenntnis wäre seine Frucht.
        </p>

    </div>)

}