export default function IdealOfGoodnessBa() {

    return (<div className='post-body'>
        <h1 className='post-title'>Ideal dobrote u djelu “Idiot”, Fjodora M. Dostojevskog</h1>

        <p className='post-paragraph'>
            Koliko vrijede poštenje i dobrota koja proističe iz nepatvorenog srca pojedinca? Kakav uticaj mogu imati takve osobine na spoljni svijet i koja je uloga plemenitog pojedinca u rješavanju sudbine čovječanstva? - ključna su pitanja romana “Idiot”, F.M.Dostojevskog. Roman prati život glavnog lika sa istoimenom dijagnozom, prikazujući hronološki događaje od njegovog povratka u Rusiju.
        </p>

        <p className='post-paragraph'>
            Protagonista romana, knez Lav Nikolajevič Miškin, jednog zimskog dana putovao je vozom ka svojoj domovini Rusiji, nakon što je proveo nekoliko godina na liječenju u Švicarskoj pod pokroviteljstvom plemenitog čovjeka Šnajdera. Liječio se od bolesti epilepsije, od koje je bolovao od djetinjstva, a koja mu je prouzrokovala i ozbiljna stanja duševnog rastrojstva. Njegova bolest je bila samo djelimično iscijeljena, tako da je knez i dalje bolovao od povremenih napada koji su mu obuzimali tijelo i um. Usljed njegove bolesti i utiska koji je odavao na okolinu, u Švicarskoj su ga nazivali “idiotom”, a ovaj nadimak će zadržati i u budućim poglavljima svoga života.
        </p>

        <p className='post-paragraph'>
            Međutim, svjestan svoga stanja, on nije uvijek razumijevao zbog čega zaslužuje epitet idiota. Zato će u interpretaciji djela fokus biti usmjeren na analizu njegovih osobina, prikazujući ujedno ključne događaje i likove romana.
        </p>

        <p className='post-paragraph'>
            Jedna od možda najvažnijih crta kneževog karaktera je bila ogromna naklonjenost djeci i osjećanje radosti i spokoja koje je osjećao u njihovom prisustvu. Osjećanje je bilo obostrano, jer ih je knez, podučavajući ih, tretirao kao ravne sebi i bio potpuno iskren prema njima. Zbog njegovog odnosa sa djecom Šnajder ga je okarakterisao kao “pravo dijete”, izrazivši da “samo rastom liči na odraslog, ali po razvitku, po duši, naravi, možda čak i po pameti nije odrastao i da će takav i ostati, makar šezdeset godina živio.” I sam je priznavao da “sva njegova duša teži k djeci”. Nasuprot tome, s odraslim ljudima se veoma slabo snalazio i krenuvši u stvarni svijet po povratku u Rusiju se bojao da će mu biti “dosadno i teško”. Nadao se da će njegova uljudnost i iskrenost biti dovoljni i da “valjda više od njega niko neće ni tražiti.” Međutim, njegov lik je izazivao različite reakcije. Dok su ga isti ljudi ponekad smatrali dobrodušnim, iskrenim i “plemenitim kao ideal”, u nekim drugim okolnostima su ga nazivali djetinjastim, nesretnikom, jadnikom, idiotom kojeg se može samo sažalijevati.
        </p>

        <p className='post-paragraph'>
            Još jedna zanimljiva osobina glavnog junaka jeste to da se on rijetko ljutio na očigledne pogrde i podsmijavanja na svoj račun. I sam sebe je lako krivio i javno priznavao svoje slabosti. Smatrao se nedostojnim ljubavi jedne plemićke djevojke prema njemu: “Od te pomisli on bi se zastideo; mogućnost ljubavi prema njemu, “prema takvom čovjeku kao što je on”, smatrao bi kao nešto čudovišno.” U knezu vidimo prizemnost i potpuno odsustvo oholosti.
            Za slabosti drugih ljudi je uvijek nalazio opravdanja i nije lako osuđivao njihove manjkavosti.
        </p>

        <p className='post-paragraph'>
            Keleru koji mu je s prikrivenog namjerom došao tražiti novac pristupio je s razumijevanjem, ne osuđujući ga.
        </p>

        <p className='post-paragraph'>
            <i>
                “Bilo kako bilo, ja vam nisam sudija. Ipak, kako ja mislim, to se ne može tek tako nazvati podlošću, šta vi mislite? Poslužili ste se lukavstvom da mi pomoću suza novac izmamite, ali, eto, sami se kunete da je vaša ispovest imala i drugu svrhu, plemenitu, a ne samo novčanu; a što se tiče novca, on vam je potreban za bančenje, je li tako? A to je već nakon ovakve ispovesti, razume se, malodušnost. A kako da se čovek i bančenja okani za jedan časak? To je nemogućno. Šta da se radi? Najbolje je prepustiti to vašoj savesti, šta mislite?”
            </i>
        </p>

        <p className='post-paragraph'>
            Za Lebedevljeva, još jednog čovjeka sklonom pijančenu i skandalima, je rekao:  <i>“On je vrlo nesređen i neke osobine…ali pri svemu tome nije bez srca, lukav je i na svoju ruku pametan.”</i>
        </p>

        <p className='post-paragraph'>
            Traganje za dobrim stranama kod drugih i pokušaj razumijevanja loših osobina je odlika izrazito empatičnog čovjeka, a za kneza se može reći da je bio čak pretjerano osjetljiv na tuđe nedaće. Ova karakterna osobenost ga je ponukala da se direktno uplete u slučaj spašavanja dvaju nesretnih djevojaka, nesretne i odbačene Marie tokom njegovog boravka u Švicarskoj i fatalne i osebujne, ali također nesređene i nesretne, Nastasje Filipovne - uz kneza centralne figure romana. Zajedničko obilježje sudbine ove dvije djevojke je moralno izopćenje iz društva, koje su one smatrale vlastitom krivicom. Mnogo veći uticaj na razvoj kneževog života je svakako imala Nastasja Filipovna, koja je kao mala uzeta pod skrbništvo i kasnije izdržavana kao ljubavnica u kući Afanasija Tockog. Zanesen njenom ljepotom, ali više od toga žalom, jer je sama sebe smatrala krivom za svoju sudbinu, knez je u pokušaju da je spasi od same sebe izgubio glavu i odvukao sebe u propast.
        </p>

        <p className='post-paragraph'>
            Upravo jačina zanosa koja ga je obuzimala u doticaju sa nesretnim ljudskim sudbinama, naročito ženskim, je bila pokazatelj njegovog ishitrenog temperamenta koji se ispoljavao u vidu nekontrolisanog govora, gestikulacije i emotivnih ispada. Čest emotivni naboj je crpio njegov duh i tijelo i doprinio razvoju njegove bolesti.
        </p>

        <p className='post-paragraph'>
            Dvije kontradiktornosti su se spojile u glavnom liku romana - s jedne strane čest nekontrolisan i konfuzan izražaj, a s druge umijeće pribranog izlaganja, “utančanost izražavanja i uljudnost tona” kojom je pozitivno djelovao na ostale likove. Bez obzira koji od ova dva načina ophođenja s ljudima knez ispoljavao, neosporna je bila iskrenost onoga što osjeća i govori. Mnogi bi rekli da njegova pretjerana iskrenost graniči sa nepromišljenošću, čak i sa glupošću, jer knez uz svoje poštene namjere nije posjedovao izrazite socijalne vještine, te često nije znao procijeniti u kojoj komunikativnoj situaciji bi trebao ili ne bi iznijeti svoja opažanja. Najviše zbog ove osobine su ga smatrali idiotom.
            Pred punim skupom ljudi plemićkog soja se izrazio prostodušno, iznijevši sve što mu leži na umu:
        </p>

        <p className='post-paragraph'>
            <i>
                “Ja sam ovamo došao bolna srca - nastavio je knez sve smetenije, sve brže i brže, sve čudnije i vatrenije - ja…ja sam se vas bojao, bojao se i sebe….I šta bi? našao sam fine; prostodušne ljude…O dajte da to iskažem! Mnogo sam slušao i čvrsto sam vjerovao da je u otmjenom društvu sve manir, sve mrtva forma, a suština da je isčilela. Ali sad vidim da to ne može biti….”
                “Hoću sve da objasnim, sve! sve! O, da! Vi mislite da sam utopist? Ideolog? O, ne, ja imam, bogami, sve tako jednostavne misli…Vi ne vjerujete? Vi se smiješite?...”
            </i>
        </p>

        <p className='post-paragraph'>
            Bez obzira što ga često nisu uzimali za ozbiljno i smatrali ga duševno poremećenim, knez je neosporno imao direktan uticaj na razvoj prilika većine likova romana. Prepoznavši u njemu plemenitog idealistu, mnogi su mu likovi bili naklonjeni i dolazili obraćati se za pomoć. Upravo kneževa nepokolebljiva vjera u ljudsko dobro i neumjerenost osjećanja nagnali su ga da se direktno ili indirektno upliće u ljudske sudbine, saslušava ih do iznemoglosti, izmiruje, ubjeđuje i da neprestano objašnjava svoje postupke. Kriveći sebe uvijek iznova za preokret događaja, tuđe razmirice, knez je preuzimao odgovornost za tuđa osjećanja i tuđe životne prilike. Imao je sklonost da saosjeća sa ljudskim nedaćama do vlastitog uništenja.
        </p>

        <p className='post-paragraph'>
            Činjenica je da je manjak socijalne inteligencije, odsustvo mentalnih i emotivnih granica u odnosu s drugima kneza toliko iscrpilo da je njegova bolest kulminirala toliko, da je baš on postao taj o kojem su se na kraju opet morali starati drugi.
        </p>

        <p className='post-paragraph'>
            Do tog stanja ga je dovelo njegovo nerazumijevanje svijeta, iako je ljude znao donekle da procijeni. Ipak, preduga izolovanost od realnog svijeta odraslih je pridonijela tome da se ne umije snaći niti prozreti ljudske namjere. Njegova nesnađenost ga je najviše koštala u odnosu sa Aglajom i Nastasjom. Ne razumjevši njihova očekivanja od njega, pa ni vlastita osjećanja i potrebe, uticao je na dramatičan razvoj sudbine obje djevojke, a na kraju i na svoju.
        </p>

        <p className='post-paragraph'>
            Nazvaćemo naivnim kneževu parolu “Ljepota će spasiti svijet.” Naivnom utoliko što je Dostojevski na njegovom primjeru pokazao da čistoća srca jednog pojedinca itekako ima djelovanje u “rješavanju sudbine čovječanstva”, ali da idealiziran pristup neprilagođen socijalnom kontekstu i vremenu u kojem čovjek živi može djelovati destruktivno.
        </p>

        <p className='post-paragraph'>
            Na kraju ćemo zaključiti da je sklad izoštrenog uma, čistog srca i iskrenog djelovanja ideal ka kojem treba stremiti i koji može polučiti pozitivne promjene. Samo ljepota uravnoteženog bića može spasiti svijet.
        </p>

    </div>)

}