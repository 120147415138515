export default function IdealOfGoodnessEn() {

    return (<div className='post-body'>
        <h1 className='post-title'>Ideal of goodness in the novel “Idiot” from F.M.Dostoyevsky</h1>

        <p className='post-paragraph'>
            How worthy are honesty and goodness of a clean heart? How can these qualities have an impact on the outside world and what is the role of some noble individual in solving the world’s destiny? - these are the main questions of a novel “Idiot” by F.M.Dostoyevsky. The novel follows the life of the main character with the same-given diagnosis by telling the happenings chronologically starting with his return to Russia.
        </p>

        <p className='post-paragraph'>
            The novel protagonist, prince Lev Nikolayevich Myshkin, was traveling by train on a winter day to his motherland Russia, after he had spent a couple of years on the illness treatment in Switzerland under care of a noble man named Schneider. He was treated from epilepsy, which he had suffered from since early childhood and which caused him serious states of mental distractibility. His illness was just partly cured, so that the prince still had attacks from time to time that possessed his body and mind. Due to the impression he left on his surroundings, he was dubbed Idiot during his life in Switzerland and this nickname will be kept in the following chapters of his life, too.
        </p>

        <p className='post-paragraph'>
            Conscious of his state he did not always understand why he deserved the label idiot. Therefore, in the novel interpretation the focus will be shifted  to the analysis of his qualities showing the main events and roman characters.
        </p>

        <p className='post-paragraph'>
            Perhaps, one of the most important features of the prince’s character was his enormous fondness for children and the feeling of joy and peacefulness he had in their company. The feeling was mutual, because the prince treated them as himself equal while teaching them and he was totally honest to them. Because of his relation with kids Schneider described him as “a real child”, pointing out that “he looked like an adult judged by only his physical appearance”. “On the other side, judged by his mental state, soul, temperament, even by his state of mind he did not leave an impression of a grown man and that will probably follow him, even if he lived until the age of 60.” He alone admitted that “his whole soul craves for children”. On the other hand, he struggled with adults and before his return to Russia he was afraid that it would be “boring and difficult” to deal with them. He hoped that his sole politeness and honesty would be enough and that “no one will expect more from him”. However, his appearance provoked different reactions. While the same people considered him as honest and noble as an ideal, under different circumstances they described him as childish, unfortunate, poor man, an idiot for whom one can only feel sorry.
        </p>

        <p className='post-paragraph'>
            Another interesting feature of a main character is that he barely got angry about obvious offenses and about the fact that he was constantly taunted at. He was easily blaming himself and openly admitted his weakness. He considered himself unworthy of love by a noble girl: “He was ashamed of such thought; the possibility that someone may love him, “love such a man like he was”, he considered as something monstrous.” His character shows a down-to-earthness and complete lack of pride. He always justified the weaknesses of other people and did not easily judge their flaws.
        </p>

        <p className='post-paragraph'>
            One feature of his noble character was shown by the way he acted towards Keler who came to him with the secret intention of seeking money from him, where the prince showed a lot of understanding without any judgement.
        </p>


        <p className='post-paragraph'>
            For Lebedev, another man who was used to drink and scandals, he said:
        </p>

        <p className='post-paragraph'>
            <i>
                "There is much that might be improved in him," said the prince, moderately, "but he has some qualities which — though amid them one cannot but discern a cunning nature — reveal what is often a diverting intellect."
            </i>
        </p>

        <p className='post-paragraph'>
            Searching for good features in others and attempting to understand the bad ones is a treat of a very empathic person and for a prince it could be said that he was over sensitive towards the misfortune of other people. This personally feature led him to get directly involved into the rescuing of two unluckily young girls, the unhappy and outcast Marie during his stay in Switzerland, and the fatal and extraordinary, but also disoriented and unhappy Nastassja Filippovna - who played the central role in the novel, along the prince. The common destiny element of these two young women was a morale excretion from the society that they blamed themselves for. A much more important influence on the development of the prince's life was definitely Nastassja Filippovna, who was fostered when she was a child and spent one part of her life being a mistress in the house of Afanassy Totsky. Obsessed by her beauty, but also feeling sorry for her self blame, the prince lost his head over her and ruined his own life while trying to save her from herself.
        </p>

        <p className='post-paragraph'>
            Precisely the strength of enthusiasm towards unfortunate human destinies, especially female ones, was an indicator of his reckless temperament which was shown through uncontrolled speech, gesticulation and emotional outburst. Frequent emotional tension exhausted his soul and body and contributed to the development of his illness.
        </p>

        <p className='post-paragraph'>
            Two opposites were combined in the main character of the novel - frequent uncontrolled and chaotic expression on one side, and the ability of a collectedness, “subtle speech and kind tone”, which made a positive impact on other characters. Regardless of which of these two ways he dealt with people, a sincerity of what he felt and said was not doubtful. Many would say that his overrated sincerity was in the close proximity with frivolousness, even with dumbness, because, besides his sincere intentions, the prince did not possess good social skills, so that he often couldn’t judge whether he should express his observations or not in some social interactions. Especially because of this feature people considered him as an idiot.
        </p>

        <p className='post-paragraph'>
            In front of the full crowd of people of noble origin he expressed himself naively, stating out everything that was on his mind.
        </p>

        <p className='post-paragraph'>
            <i>
                "I came into this room with anguish in my heart," continued the prince, with ever-growing agitation, speaking quicker and quicker, and with increasing strangeness. "I — I was afraid of you all, and afraid of myself…
                …"Well? What have I seen?" he continued. "I have seen men of graceful simplicity of intellect; …Oh, let me express this feeling! I have so often heard, and I have even believed, that in society there was nothing but empty forms, and that reality had vanished; but I now see for myself that this can never be the case here, among us — it may be the order elsewhere, but not in Russia…”
            </i>
        </p>

        <p className='post-paragraph'>
            <i>
                "I want to explain all to you — everything — everything! I know you think me Utopian, don't you — an idealist? Oh, no! I'm not, indeed — my ideas are all so simple. You don't believe me? You are smiling?...”
            </i>
        </p>

        <p className='post-paragraph'>
            Although the others often didn’t take him seriously and did consider him as mentally disordered, the prince had without doubt a direct impact on the destiny development of many novel characters. Because they recognized a noble idealist in him, they were pretty close to him and came to ask help. His unshaken faith in the people’s good and his overweening feelings led him to directly or indirectly get involved into people’s destinies, to listen to them until he gets exhausted, to reconcile, persuade them and to explain his acts constantly. By blaming himself over and over again for the turn of events, people’s spats, the prince took responsibility for others' feelings and their life circumstances. He used to suffer with people’s misfortunes until the self/destruction.
        </p>

        <p className='post-paragraph'>
            The fact is that the poor social intelligence and the lack of mental and emotional boundaries in the relation with others exhausted the prince so much that exactly he became a person that others had to take care of in the end. je da je manjak socijalne inteligencije, odsustvo mentalnih i emotivnih granica u odnosu s drugima kneza toliko iscrpilo da je njegova bolest kulminirala toliko, da je baš on postao taj o kojem su se na kraju opet morali starati drugi.
        </p>

        <p className='post-paragraph'>
            His misunderstanding of the world led him to this state, although he had some ability to estimate people. But his very long isolation from the real world of the adults was a reason why he could not find his way among the people, neither to look through their intentions. His disorientation cost him the most in the relationship with Aglaya and Nastassja. He did not understand their expectation of him, nor his own feelings and needs, and therefore his actions caused a dramatic destiny outcome of the both young ladies, and in the end his own, too.
        </p>

        <p className='post-paragraph'>
            I would say that the prince’s motto “Beauty will save the world.” is naive. Dostoyevsky showed on the example of the prince that the purity of a heart of one individual surely has an impact on the “solving the world’s destiny”, but an idealistic approach that is unadapted to the social context and the current century could be destructive.
        </p>

        <p className='post-paragraph'>
            In the end I would conclude that the harmony of a sharp mind, a clean heart and honest acts is an ideal we have to strive for. Such balance can make positive changes. Only the beauty of a balanced human being can save the world.
        </p>
    </div>)

}