import React from 'react';
import './NounArticles.css';

export default function NounArticlesBa() {

    return (
        <div className='post-body'>
            <h1 className='post-title'>Učenje rodova imenica metodom grupisanja i vizualizacije</h1>

            <p className='post-paragraph'>
                Učenje članova imenica u njemačkom jeziku može biti veoma zbunjujuće za mnoge učenike.
                Uzalud traže odgovore na pitanje zbog čega je imenica <i>sunce (die Sonne)</i> ženskog, <i>stolica (der Stuhl)</i> muškog, a <i>djevojčica (das Mädchen)</i> srednjeg roda.
                Ne postoji čarobni štapić koji će pomoći da izvučemo tačne članove imenica kao iz rukava, ali zato se možemo koristiti vlastitom logikom!
                U opisu u nastavku predstavljam jednostavnu metodu za učenje članova.
                Imenice bi trebalo prije svega učiti tematski, svedene pod viši, općeniti pojam.
                Na ovaj način više pojmova sortiramo u istu ladicu u mozgu i znamo gdje trebamo posegnuti kada nam zatreba određena imenica.
                Imenice tada postoje kao apstraktni pojmovi u mozgu kojima možemo dodati oblik, boju, veličinu, dakle određeniju pojavu.
                To ćemo postići tako što ćemo imenice određene kategorije nacrtati, uslikati ili napraviti.
                Što konkretnije riječi predstavimo, odnosno vizualiziramo, utoliko lakše će se one uvriježiti u našem mozgu.
                A šta ćemo sa članovima?
                Njih ćemo također učiti sistematski, tako što ćemo skupiti zajedno imenice istog roda na određenu temu.
            </p>

            <p className='post-paragraph'>
                Za primjer ćemo uzeti imenice na temu namještaja.
                Na slici ispod je prikazana elegantna trpezarija.
                Sve imenice koje označavaju predstavljene predmete su muškog roda - <i>der Teppich, der Tisch, der Stuhl, der Kronleuchter</i> – najvažniji komadi namještaja jedne trpezarije.
                Onda slobodno možemo reći da je trpezarija muškog roda.
                Na isti način ćemo zapamtiti da je dnevni boravak ženskog roda, obzirom da glavni predmeti predstavljaju die- imenice, imenice ženskog roda (<i>die Couch, die Kommode, die Lampe, die Blume</i>).
                Već vidimo jasno da je spavaća soba srednjeg roda (das Bett, das Kissen, das Nachtkästchen, das Bild) – ovdje možemo pridodati još i: <i>das Regal, das Buch, das Fenster</i>.
            </p>

            <img className='post-image-noun' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/dinner-room.webp'} alt="bookshelf" />
            <img className='post-image-noun' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/living-room.webp'} alt="bookshelf" />
            <img className='post-image-noun' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/bedroom.webp'} alt="bookshelf" />

            <p className='post-paragraph'>
                Opisani obrazac učenja funkcioniše također i kod učenja oznaka za odjevne predmete.
                Zamislimo neku odjevnu kombinaciju u glavi ili je napravimo, nacrtajmo.
                Ako želimo uložiti malo više vremena i truda, možemo i izrezati i skupa zalijepiti slike odjevnih predmeta iz modnih časopisa.
                Na ovaj način možemo sastaviti i <i>der, die, das</i> kombinacije.
                Neki od primjera su dati na slikama koje slijede.
            </p>

            <img className='post-image-noun' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-masculin.webp'} alt="bookshelf" />
            <img className='post-image-noun' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-feminine.webp'} alt="bookshelf" />
            <img className='post-image-noun' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-neutral.webp'} alt="bookshelf" />

            <p className='post-paragraph'>
                Navedena metoda učenja djeluje na način da se pri učenju, ponavljanju i spontanoj upotrebi imenica prisjetimo određene slike i da članove zajedno sa imenicama povežemo sa općim pojmom. Tada ne moramo minutama razbijati glavu oko toga da li je član imenice <i>der, die</i> ili <i>das</i>.
            </p>

            <p className='post-paragraph'>
                Sretno i  tokom učenja pustite kreativnosti na volju!
            </p>
        </div>

    );

}
