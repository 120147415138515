export default function KindWordsEn() {

    return (<div class='post-body'>
        <h1 className='post-title'>Good words</h1>

        <p className='post-paragraph-centered'>
            <i>
                “Do you not see how Allah compares a good word to a good tree? Its root is firm and its branches reach the sky, ˹always˺ yielding its fruit in every season by the Will of its Lord. This is how Allah sets forth parables for the people, so perhaps they will be mindful.”
            </i>
        </p>

        <p className='post-paragraph-centered'>
            (Ibrahim 24:25)
        </p>

        <p className='post-paragraph'>
            The worth of a good word is based on the religious, but also on the universal humanitarian principle. There is an obvious intolerance between human beings, which leads the world to destruction and the reason for that is a slag of bad thoughts about others, especially about the different ones. A bad thought leads to a bad word, a bad word to a bad deed and eventually to a bad relationship. To be able to say some good words to someone, we firstly have to purify our hearts from bad thoughts and traits. Self-reckoning and beautification of our character should be an obligation of each individual which has an important role in the world. Our thoughts, words and acts create our micro world and it influences the world of others. Because of that, it is crucial to cultivate a culture of dialogue through good words, an honest compliment, and well-intentioned advice. Good words melt hearts, relieve sadness and open the doors of every kind of good, everywhere.
        </p>

        <p className='post-paragraph'>
            But the beauty of an expression depends on the individual consciousness, on the point of view, the vocabulary range and other language skills. It’s the ability reflection of an established person and not some formally learnt expression. Behind a good word there should be an honest intention and only such words can reach the other person. A root of a good word is a clean heart which branches are good words and honest deeds and the recognition is its fruit.
        </p>

    </div >)

}