export default function KindWordsBa() {

    return (<div class='post-body'>
        <h1 className='post-title'>Lijepa riječ</h1>

        <p className='post-paragraph-centered'>
            <i>
                „Lijepa riječ je kao lijepo drvo: korijen mu je čvrsto u zemlji, a grane prema nebu,ono plod daje u svako doba koje Gospodar njegov odredi, a Allah ljudima navodi primjere da bi pouku primili.“
            </i>
        </p>

        <p className='post-paragraph-centered'>
            (Kur'an,14/24-26)
        </p>

        <p className='post-paragraph'>
            Vrijednost lijepe riječi počiva na religijskom, ali i univerzalnom principu humanosti. Činjenica je da među ljudima vlada netrpeljivost koja svijet vodi ka destrukciji, a uzrok toga je talog loših misli prema ljudima, posebno prema drugom i drugačijem. Loša misao vodi ka ružnoj riječi, ružna riječ ka ružnom postupku, ka ružnom odnosu. Da bismo bili u stanju dati lijepu riječ, moramo prije svega očistiti srce od loših misli i osobina. Samoobračun i uljepšavanje vlastitog karaktera je obaveza svakog pojedinca koji ima važnu ulogu u svijetu. Naše misli, riječi i postupci kreiraju naš mikrosvijet, a on utiče na svijet drugoga. Zbog toga je suštinski važno njegovati kulturu komuniciranja kroz lijepu riječ, iskren kompliment, dobronamjeran savjet. Lijepa riječ topi srca, ublažava tugu i otvara vrata dobra posvuda.
        </p>

        <p className='post-paragraph'>
            Ljepota izraza ipak zavisi od lične osviještenosti, svjetonazora, širine vokabulara i drugih jezičkih kompetencija. Ona je odraz umijeća zaokruženog bića, a ne samo formalni naučeni iskaz. Iza lijepe riječi mora stajati iskrena namjera i jedino kao takva, riječ može doprijeti do drugoga. Korijen lijepe riječi je čisto srce čije su grane lijepe riječi i iskrena djela, a plodovi njegovi spoznaja.
        </p>
    </div>)

}