import React from 'react';
import './NounArticles.css';

export default function NounArticlesDe() {
  return (
    <div className='post-body'>
      <h1 className='post-title'>Substantivartikel visuell und als eine Einheit lernen</h1>

      <p className='post-paragraph'>
        Das Lernen von Substantivartikeln im Deutschen kann für viele Deutschlernende verwirrend sein.
        Vergeblich suchen sie nach den Antworten auf die Frage, warum <i>die Sonne</i> feminin, <i>der Stuhl</i> maskulin oder <i>das Mädchen</i> neutral ist.
        Es gibt keinen Zauberstab, mit dem man tausende Substantivartikel wie aus dem Stegreif richtig nennen kann, aber dafür können wir uns unserer eigenen Logik bedienen!
        In der folgenden Beschreibung stelle ich eine einfache Methode zum Lernen von Artikeln dar. Substantive sollte man vor allem thematisch, gebunden an einen Oberbegriff, lernen.
        Auf diese Weise „sortieren“ wir mehrere Begriffe in eine gemeinsame Schublade im Gehirn und wissen dann, wo wir bei Bedarf nach einem bestimmten Substantiv greifen sollen.
        Die Substantive bestehen jetzt als abstrakte Begriffe in unserem Gehirn, denen wir jetzt eine Form, eine Farbe, eine Größe, also ein genaueres Erscheinungsbild geben können.
        Dies erreichen wir, indem wir die Substantive einer bestimmten Kategorie in einem Bild zeichnen, malen oder basteln.
        Je konkreter wir die Wörter darstellen, bzw. visualisieren, desto leichter werden sie in unserem Gehirn geprägt.
        Und was ist mit den Artikeln? Die werden dann auch systematisch erlernt, indem wir Substantive des gleichen Genus zum bestimmten Thema zusammenbringen.
      </p>

      <p className='post-paragraph'>
        Als Beispiel nehmen wir Substantive zum Thema <i>Möbel</i>.
        Auf dem ersten Bild unten wird ein elegantes Esszimmer gezeigt.
        Alle Substantive, die die dargestellten Gegenstände bezeichnen, sind maskulin - <i>der Teppich, der Tisch, der Stuhl, der Kronleuchter</i> – die wichtigsten Möbelstücke in einem Esszimmer.
        Wir können dann frei sagen, das Esszimmer ist maskulin. Auf die gleiche Art und Weise merken wir uns, dass das Wohnzimmer feminin ist, da die meisten Gegenstände <i>die</i> Substantive sind (<i>die Couch, die Kommode, die Lampe, die Blume</i>).
        Es wird dann schon klar, dass das Schlafzimmer neutral ist (<i>das Bett, das Kissen, das Nachtkästchen, das Bild</i>) – dazu könnte man noch <i>das Regal, das Buch, das Fenster</i> zählen.
      </p>

      <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/dinner-room.webp'} alt="bookshelf" />
      <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/living-room.webp'} alt="bookshelf" />
      <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/bedroom.webp'} alt="bookshelf" />

      <p className='post-paragraph'>
        Das beschriebene Lernmuster funktioniert auch bei den Bezeichnungen für die Kleidungsstücke. Stellen wir ein Outfit in unserem Kopf vor und basteln oder zeichnen wir es. Wenn wir uns mehr Zeit und Mühe geben würden, könnten wir aus Modezeitschriften Fotos der Kleidungsstücke ausschneiden und zusammenkleben. Auf diese Weise können wir auch <i>der, die, das</i> Kleidungskombinationen zusammenstellen.
        Einige Beispiele befinden sich auf den folgenden Fotos.
      </p>

      <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-masculin.webp'} alt="bookshelf" />
      <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-feminine.webp'} alt="bookshelf" />
      <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-neutral.webp'} alt="bookshelf" />

      <p className='post-paragraph'>
        Die beschriebene Lernmethode wirkt auf die Weise, dass wir uns beim Lernen, Wiederholen und dem spontanen Gebrauch von Substantiven an ein konkretes Bild erinnern und die Artikel zusammen mit den Substantiven mit einem Oberbegriff verbinden.
        Dann müssen wir uns nicht minutenlang den Kopf über <i>der, die, das</i> zerbrechen.
      </p>


      <p className='post-paragraph'>
        Viel Glück und lassen Sie Ihrer Kreativität beim Lernprozess freien Lauf!
      </p>
    </div>

  );
}
