import React from 'react';
import './CategoriesOverview.css';
import CategoryPreview from '../CategoryPreview/CategoryPreview';
import categories from '../../data/categories';
import { LanguageContext } from '../../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';

export default function CategoriesOverview(properties) {

    const { language } = React.useContext(LanguageContext);
    const { t, i18n } = useTranslation();

    function getTitle(category) {
        if (language.language === 'ba') {
            return category.ba.title;
        } else if (language.language === 'de') {
            return category.de.title;
        } else {
            return category.en.title;
        }
    }

    function getAbstract(category) {
        if (language.language === 'ba') {
            return category.ba.abstract;
        } else if (language.language === 'de') {
            return category.de.abstract;
        } else {
            return category.en.abstract;
        }
    }

    return (
        <div className="overview">
            {
                categories.map(category =>
                    <CategoryPreview key={category.id}
                        title={getTitle(category)}
                        abstract={getAbstract(category)}
                        linkUrl={'/categories/' + category.id}
                        imageUrl={category.imageUrl}
                        buttonText={i18n.t('explore')} />
                )
            }
        </div>
    )
}