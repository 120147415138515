import React from 'react';
import './NounArticles.css';

export default function NounArticles() {
    return (
        <div className='post-body'>
            <h1 className='post-title'>Learning the noun articles using the method of clustering and visualizing</h1>

            <p className='post-paragraph'>
                Learning the noun articles in German language can be very confusing for many learners.
                They are hopelessly asking for an answer to the question: why is <i>sun (die Sonne)</i> feminine, <i>chair (der Stuhl)</i> masculine and <i>girl (das Mädchen)</i> of neutral gender?
                There is no magic wand that will help us name the noun articles off the cuff, but instead, we can use our own logic!
                In the following description, I introduce one simple method for learning gender. Nouns should primarily be learned thematically, grouped under a higher, general concept. In this way more terms are beeing collected in the same drawer in our brain, so that we know where to look for a cetran noun when we need it.
                The nouns exist as abstract terms in the brain to which we can add shape, colour, size, so they get a more certain manifestation.
                We will achieve this by drawing, photographing, or creating nouns of a specific category as one picture.
                The more concrete we represent, or visualize the words, the easier they will be ingrained in our brain.
                And what about the articles? We will also learn them systematically by gathering together nouns of the same gender on a specific topic.
            </p>

            <p className='post-paragraph'>
                We will choose the nouns of the furniture topic as an example. In the picture below, an elegant dining room is shown. All nouns that represent objects in the photo are masculine - <i>der Teppich, der Tisch, der Stuhl, der Kronleuchter</i> – the most useful furniture pieces in a dining room."
            </p>

            <p className='post-paragraph'>
                Then we can freely say that the dining room is of masculine gender.
                Similarly, we will remember that the living room is of feminine gender, considering that the main items represent die-nouns, feminine gender nouns (<i>die Couch, die Kommode, die Lampe, die Blume</i>).
                We already see clearly that the bedroom is of neuter gender (<i>das Bett, das Kissen, das Nachtkästchen, das Bild</i>) – here we can also add: das Regal, das Buch, das Fenster.
            </p>

            <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/dinner-room.webp'} alt="bookshelf" />
            <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/living-room.webp'} alt="bookshelf" />
            <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/bedroom.webp'} alt="bookshelf" />

            <p className='post-paragraph'>
                The described learning pattern also works when learning labels for clothing items.
                Let's imagine a clothing combination in our head or create one, draw it.
                If we want to invest a little more time and effort, we can cut out and paste together pictures of clothing items from fashion magazines.
                In this way, we can also put together <i>der, die, das</i> combinations. Some examples are given in the following pictures.
            </p>

            <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-masculin.webp'} alt="bookshelf" />
            <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-feminine.webp'} alt="bookshelf" />
            <img className='post-image' src={process.env.PUBLIC_URL + '/images/posts/noun-articles/clothing-neutral.webp'} alt="bookshelf" />

            <p className='post-paragraph'>
                The mentioned learning method works in a way that when learning, repeating, and using nouns spontaneously, we remember a specific image and connect the articles with the nouns to a general concept. Then we don't have to spend minutes figuring out whether the article of the noun is <i>der, die</i>, or <i>das</i>.
            </p>

            <p className='post-paragraph'>
                Good luck, and let your creativity run wild during the learning process!
            </p>
        </div>
    )
}
