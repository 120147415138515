export default function ExercisingLanguageSkillsBa() {

    return (<div className='post-body'>
        <h1 className='post-title'>Uvježbavanje vještine govora (i pisanja!) na njemačkom jeziku uz pomoć monologa</h1>

        <p className='post-paragraph'>
            Kod učenja njemačkog i bilo kojeg drugog stranog jezika je tečna i ispravna komunikacija glavni cilj. Mnogi imaju poteškoće postići ga, iako kao osnovu imaju dobro poznavanje gramatike i dovoljan spektar riječi. Problem je u nedovoljnoj posvećenosti ovoj vještini u procesu učenja stranog jezika i u nepoznavanju tehnika za vježbu iste. U nastavku pročitajte kako sami već od A2 nivoa možete vježbati komunikaciju na njemačkom kroz monologe.
        </p>

        <p className='post-paragraph'>
            Prvi korak ka uspješnoj komunikaciji je svakako konstantno usvajanje i uvježbavanje vokabulara i gramatičkih jedinica. Ono što je veoma važno kada učimo riječi jeste da ih učimo u frazama, nipošto pojedinačno i van konteksta. Uzmimo za primjer temu <b>Urlaub am Meer (odmor na moru)</b>. Sada se prisjetimo osnovnih fraza na ovu temu kojima opisujemo naše asocijacije na aktivnosti na moru. “Izvucimo” iz sjećanja prve izraze koji nam naumpadnu. To bi otprilike bile sljedeće fraze: <span className="highlighter-text-red"><b>am Strand liegen</b> (ležati na plaži), <b>sonnenbaden</b> (sunčati se) <b>im Meer schwimmen</b> (plivati u moru), <b>heiß sein</b> (biti vruće)</span>, itd. Kao što vidimo, pod frazama podrazumijevamo sljedeće kombinacije: priloška odredba-glagol, pridjev-glagol, imenica-glagol, itd. Učenje riječi u kombinacijama nam pomaže da brže i lakše tvorimo rečenice uz jednostavno dodavanje subjekta.
        </p>

        <p className='post-paragraph'>
            Da vidimo kako ćemo se izraziti na temu odmora na moru u vidu kraćeg monologa:
        </p>

        <p className='post-paragraph'>
            Uzmimo frazu <span className="highlighter-text-red"><b>Urlaub am Meer machen</b> (odmarati se na moru)</span> i proširimo je dodavanjem ostalih riječi koje poznajemo na tu temu. Tako možemo reći: <span className="highlighter-text-red"><b>Urlaub am Meer gern machen</b> (rado se odmarati na moru), <b>zweimal pro Jahr Urlaub am Meer machen</b> (dvaput godišnje se odmarati na moru), <b>im Sommer Urlaub am Meer machen</b> (ljeti se odmarati na moru), ili formulišimo varijacije: <b>im Sommer ans Meer fahren</b> (ljeti ići na more), <b>mit Freunden ans Meer fahren</b> (s prijateljima ići na more), <b>mit dem Auto ans Meer fahren</b> (ići autom na more), <b>heiß sein</b> (biti vruće), <b>Spaß haben</b> (zabavljati se)</span>. Sada počnimo sa tvorbom jednostavne rečenice tako što ćemo iskoristiti jednu od navedenih fraza i dodati subjekat: <span className="highlighter-text-red"><b>Ich mache gern Urlaub am Meer.</b> (Rado se odmaram na moru)</span>. Sada samo nastavimo dalje, koristeći ostale fraze i jednostavnije veznike poput und, aber, oder, dass ili wenn:
        </p>

        <p className='post-paragraph'>
            <span className="highlighter-text-red">
                <b>
                    Ich mache gern Urlaub am Meer. Zweimal pro Jahr fahre ich mit meinen Freunden nach Kroatien. Es ist sehr heiß dort, aber wir haben viel Spaß. Wir schwimmen im Meer, essen Eis und trinken kalte Getränke. Ich hoffe, dass wir auch dieses Jahr auch zusammen gehen.
                </b>
            </span>
        </p>

        <p className='post-paragraph'>
            Formulisali smo jedan monolog na A2 nivou. Od B1 nivoa ga možemo već proširiti uz nove veznike i strukture: <i>obwohl, infinitiv sa zu</i> i novim frazama, kao što su npr. <span className="highlighter-text-red"><b>am Strand sitzen</b> (sjediti na plaži), <b>ein Buch lesen</b> (čitati knjigu), <b>den Seeblick genießen</b> (uživati u pogledu na more), <b>Meeresrauschen hören</b> (slušati morske šumove)</span>. Tokom upotrebe jednostavnijih izraza i veznika već smo aktivirali “uspavani” vokabular na datu temu i lakše se prisjećamo kompleksnijih fraza i gramatičkih struktura. Tada, korak po korak, možemo početi sa tvorbom kompleksnijih rečenica.

        </p>

        <p className='post-paragraph'>
            <b>
                <span className="highlighter-text-red">
                    Ich mache gern Urlaub am Meer. Zweimal pro Jahr fahre ich mit meinen Freunden nach Kroatien. Es ist sehr heiß dort, aber wir haben viel Spaß. Wir schwimmen im Meer, essen Eis und trinken kalte Getränke. Ich hoffe, dass wir auch dieses Jahr auch zusammen gehen.
                </span>
                &nbsp;
                <span className="highlighter-text-purple">
                    <u>Obwohl</u> meine Freunde immer sonnenbaden, mag ich das nicht. Lieber lese ich ein Buch im Schatten. Ich genieße es auch, am Strand <u>zu sitzen</u> und Meeresrauschen <u>zu hören.</u>
                </span>
            </b>
        </p>

        <p className='post-paragraph'>
            Dobili smo prošireni monolog uz nekoliko jednostavnih izraza i veznika. Uz svaku naučenu frazu ili gramatičku strukturu možemo proširiti monolog.
        </p>

        <p className='post-paragraph'>
            Cilj ove metode je da pri odgovoru na određenu govornu situaciju aktiviramo već poznatu leksiku njemačkog jezika. Mnogi čine grešku pokušavajući prevesti zamišljeni tekst u glavi sa maternjeg na njemački jezik. Na taj način se zapetljaju u zamršenim strukturama koje ne znaju prevesti. Umjesto toga, cilj je da vizualizujemo datu situaciju, “prizovemo” asocijacije i prisjetimo se osnovnih izraza koje već znamo. Tada ćemo lakše aktivirati i druge izraze na datu temu i tvoriti složenije rečenice.
        </p>

        <p className='post-paragraph'>
            Svakako da su greške u ovom procesu neizostavne, ali ključno je da koristimo fraze koje usvajamo i vježbamo tehnike kako ih kombinovati u složenije forme poput rečenice i teksta.
        </p>

        <p className='post-paragraph'>
            Važno je naglasiti da na potpuno isti način možemo trenirati i vještinu pisanja!
            Izaberite svoju omiljenu temu i započnite monolog.
        </p>
    </div>)

}