import SentenceBuildingBa from "./SentenceBuildingBa";
import SentenceBuildingDe from "./SentenceBuildingDe";
import SentenceBuildingEn from "./SentenceBuildingEn";
import './SentenceBuilding.css';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';

export default function SentenceBuilding() {

  const { language } = React.useContext(LanguageContext)

  if (language.language === 'ba') {
    return SentenceBuildingBa()
  } else if (language.language === 'de') {
    return SentenceBuildingDe()
  } else {
    return SentenceBuildingEn()
  }

}