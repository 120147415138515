import categories from '../../../data/categories';
import React from 'react';
import { LanguageContext } from '../../../contexts/LanguageContext';
import './GiftOfTime.css';

export default function GiftOfTime() {

    const { language } = React.useContext(LanguageContext)

    function getTitle() {
        if (language.language === 'ba') {
            return categories[3].ba.posts[0].title;
        } else if (language.language === 'de') {
            return categories[3].de.posts[0].title;
        } else {
            return categories[3].en.posts[0].title;
        }
    }

    function getBosnianContent() {
        return (<>
            <p className='post-paragraph'>
                Oni koji su došli u doticaj sa njemačkom kulturom složili bi se da se Nijemci odlikuju tačnošću. Ljudima koji su naviknuti na opušteniji životni stil, čini se pak pretjeranim da se u svakoj sferi svakodnevnog života moraju držati tačno dogovorenih termina.
            </p>
            <p className='post-paragraph'>
                Kao stipendistkinja sam provela određeno vrijeme u Njemačkoj i iz mog ugla gledanja Nijemci znaju cijeniti svoje i tuđe vrijeme. Naročito u poslovnoj sferi života je osjetan ovaj fenomen. Radno vrijeme banaka, prodavnica, frizerskih salona i restorana je tačno utvrđeno. Autobusi dolaze na stanicu na vrijeme, kako je i navedeno u planu vožnje, a ukoliko i dođe do kašnjenja, putnici bivaju obaviješteni. Saradnju sa docentima na univerzitetima također dijelim kao veoma pozitivno iskustvo. Predavači se drže utvrđenog vremena održavanja predavanja, a pauze završavaju kada je predviđeno. Nema prostora za „još jednu brzinsku kafu“ prije toga. Konsultacije se moraju najaviti sedmicu ranije i traju sasvim dovoljno kako bi studenti mogli optimalno iskoristiti svoje termine.
            </p>
            <p className='post-paragraph'>
                Za ljude koji dolaze iz zemalja u kojima se ovaj princip ne poštuje na navedeni način, ovakav set pravila može biti veoma stresan. Međutim, za mene su dva aspekta njemačke kulture – pouzdanost i njihov odnos prema vremenu – temeljni i neophodni principi na kojima raste uspješno društvo. Činjenica da se možete pouzdati u dogovorene termine znači da ste u prednosti, jer možete planirati svoje vrijeme.
            </p>
            <p className='post-paragraph'>
                Način na koji se jedno društvo odnosi prema vremenu mnogo govori o njemu. Vrijeme je dar koji nam stoji na raspolaganju do određenog trenutka. Zbog toga razuman i odgovoran čovjek neće svoje vrijeme trošiti uzalud, već će sebi postaviti vrijedne životne ciljeve na kojima će svakodnevno raditi. Čovjek treba biti u stalnom nastojanju da aktivno djeluje i bude koristan svojoj zajednici. U Kur'anskoj suri „Al-Asr“ („Vrijeme“) naglašen je aspekat potrošnosti čovjeka i brzine prolaznosti vremena. Sura nam daje pouku da je onaj koji ne koristi vrijeme za činjenje dobra sebi i drugima na gubitku, jer moderni čovjek preokupiran samim sobom često nesvjesno radi protiv sebe i društva. Stoga činjenje dobra treba biti utkano u svaki segmenat našeg života, pa i tamo gdje to nije očigledno.
            </p>

            <p className='centered-quote'>
                Tako mi vremena -
            </p>
            <p className='centered-quote'>
                čovjek, doista, gubi,
            </p>
            <p className='centered-quote'>
                samo ne oni koji vjeruju i dobra djela čine, i koji jedni drugima istinu preporučuju i koji jedni drugima preporučuju strpljenje.
            </p>
            <p className='centered-quote'>
                Al - 'Asr (Vrijeme)
            </p>
        </>)
    }

    function getGermanContent() {
        return (<>
            <p className='post-paragraph'>
                Diejenigen, die in Kontakt mit deutscher Kultur gekommen sind, würden sich darüber einigen, dass sich die Deutschen durch ihre Pünktlichkeit auszeichnen. Menschen, die an einen mehr entspannten Lebensstil gewöhnt sind, scheint es aber übertrieben, dass sie sich in jedem Bereich des Alltagslebens an die vereinbarten Termine genau halten müssen.
            </p>
            <p className='post-paragraph'>
                Als Stipendiatin habe ich eine gewisse Zeit in Deutschland verbracht und aus meiner Sicht wissen die Deutschen ihre und die Zeit der anderen zu schätzen.
            </p>
            <p className='post-paragraph'>
                Im Berufsleben ist dieses Phänomen äußerst bemerkbar. Die Öffnungszeiten der Banken, Läden, Friseursalons, Restaurants sind fest geregelt. Busse kommen pünktlich an die Haltestelle, wie im Fahrplan angegeben, und wenn es Verspätung gibt, wird den Fahrgästen Bescheid darüber gegeben. Sehr positive Erfahrung hatte ich auch mit der Zusammenarbeit mit Universitätsdozenten gehabt. Die Besprechungsstunden sollen eine Woche im Voraus geplant werden und dauern genug, damit Studierende optimal ihre Termine ausnutzen können. Die Dauer der Vorlesungen an der Uni steht fest und die Zwischenpausen enden rechtzeitig. Es gab keinen Zeitraum für „noch einen kurzen Kaffee“ davor.
            </p>
            <p className='post-paragraph'>
                Menschen, die aus Ländern kommen, in denen dieses Prinzip nicht auf die beschriebene Weise respektiert wird, kann solche Ordnung viel Stress verursachen. Ich sehe aber die zwei Aspekte der deutschen Kultur, Verlässlichkeit und den respektvollen Umgang mit Zeit, als grundlegende und notwendige Prinzipien, auf denen eine erfolgreiche Gesellschaft wächst.
            </p>
            <p className='post-paragraph'>
                Wenn man weiß, dass man sich auf eine bestimmte Vereinbarung verlassen kann, dann hat das viele Vorteile, weil man dementsprechend seine Zeit planen kann. Der Umgang mit Zeit sagt viel über eine Gesellschaft aus. Zeit ist die Gabe, die uns zur Verfügung bis zum bestimmten Zeitpunkt steht. Deswegen wird ein vernünftiger und verantwortlicher Mensch seine Zeit nicht umsonst verschwenden, sondern wird sich wertvolle Lebensziele stellen und daran täglich arbeiten. Der Mensch soll in ständiger Bemühung sein, aktiv zu handeln und seiner Gemeinschaft von Nutzen zu sein. In Surah „Al-Asr“ („Die Zeit“) ist der Aspekt der menschlichen Flüchtigkeit und der Zeitvergänglichkeit betont. Diese Surah lehrt uns, dass derjenige, der nicht gut in seinen und den Nutzen der anderen tut, eigentlich verliert, weil der moderne Mensch, besessen von sich selbst, oft unbewusst gegen sich selbst und die Gesellschaft handelt. Demzufolge soll das Gutmachen in jeden Lebensbereich inkorporiert werden, auch dort, wo es nicht offensichtlich gezeigt wird.
            </p>

            <p className='centered-quote'>
                Beim Nachmittag!
            </p>
            <p className='centered-quote'>
                Die Menschen sind wahrlich im Verlust;
            </p>
            <p className='centered-quote'>
                außer denjenigen, die glauben und gute Werke tun und sich gegenseitig die Wahrheit ans Herz legen und sich gegenseitig zur Geduld anhalten.
            </p>
            <p className='centered-quote'>
                Al - 'Asr (Zeit)
            </p>
        </>)
    }

    function getEnglishContent() {
        return (<>
            <p className='post-paragraph'>
                People who got in touch with German culture could agree that punctuality is one of the features of the German people. For people who got used to a more relaxed lifestyle, the fact that they have to strictly hold on to the agreed appointments in every aspect of everyday life, could be exaggerated.
            </p>
            <p className='post-paragraph'>
                As a scholarship student I spent some time in Germany and from my point of view I would say that the German people appreciate other people's time as much as their own. This phenomenon is particularly noticeable in the business aspect of life. The working time of the banks, shops, hairdressing salons and restaurants is strictly arranged. The buses are always on time, as it is stated in the driving schedule and if there is a delay, passengers are informed. I would also share my very positive experience about the cooperation with the university docents. The lecturers abide by the arranged lecture time and the breaks end up according to the agreement. There is no space for „one more quick coffee“. Student consultings have to be announced one week before so that the students have quite enough time for the consulting.
            </p>
            <p className='post-paragraph'>
                For the people who come from countries in which this principle is not respected in this way, a load of rules can be really stressful. From my perspective, the two aspects of German culture – reliability and their awareness of time – are basic and essential principles for the growth of a successful society. Knowing that you can rely on the agreed appointments has many advantages, because you can plan your time.
            </p>
            <p className='post-paragraph'>
                The way one society behaves towards the phenomenon of time says a lot about it. Time is a gift that has been given to us until the destined moment comes. Therefore, a reasonable and responsible person will not waste his/her time on meaningless things and instead of that he/she will set important life goals and will work on them every day. People should always be trying to be active and useful in their community. In the surah “Al-Asr” (“Time”) in the Quran the aspect of human ephemerality and the quickness of time passing is emphasized. The surah teaches us that the one who does not use the time for doing good to himself/herself and to others is at a loss, because modern people often act against themselves and the society while being occupied by their own needs. Based on that understanding, doing good should be incorporated in every aspect of our life, even where it is not obviously shown.
            </p>

            <p className='centered-quote'>
                (I swear) by the Time,
            </p>
            <p className='centered-quote'>
                man is in a state of loss indeed,
            </p>
            <p className='centered-quote'>
                except those who believed and did righteous deeds, and exhorted each other to follow truth, and exhorted each other to observe patience.
            </p>
            <p className='centered-quote'>
                Al - 'Asr (Time)
            </p>
        </>);
    }

    function getContent() {
        if (language.language === 'ba') {
            return getBosnianContent();
        } else if (language.language === 'de') {
            return getGermanContent();
        } else {
            return getEnglishContent();
        }
    }

    return (<div class='post-body'>
        <h1 className='post-title'>{getTitle()}</h1>
        <img className='post-headline-image' src={process.env.PUBLIC_URL + '/images/posts/gift-of-time/clock-stretched.png'} alt="clock" />

        {/* <p className='post-abstract'>{getAbstract()}</p> */}
        {getContent()}
    </div>)
}