import React from 'react';
import './CategoryPreview.css'
import { Link } from 'react-router-dom';

export default function CategoryPreview(properties) {

    return (
        <div className="card text-center">
            <img className="card-img-top category-img"
                src={process.env.PUBLIC_URL + properties.imageUrl}
                alt=""
            />
            <div className="card-body">
                <h5 className="card-title">{properties.title}</h5>
                <p className="card-text">{properties.abstract}</p>


            </div>
            <div className="footer">
                <Link to={properties.linkUrl} className="btn btn-primary">{properties.buttonText}</Link>
            </div>
        </div >
    )
}