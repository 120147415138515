import React from 'react';
import './HumanUnderstanding.css';

export default function HumanUnderstandingDe() {

    return (<div class='post-body'>
        <h1 className='post-title'>Sprache als Mittel zum Menschenverständnis</h1>
        <img className='post-headline-image' src={process.env.PUBLIC_URL + '/images/posts/human-understanding/sokrat-wide.webp'} alt="bookshelf" />

        <p className='post-paragraph'>
            Kein Mensch besitzt die Gabe, die Gedanken des anderen zu lesen. Mangelnde Kommunikation ist die Ursache fast aller Missverständnisse und das Gespräch ist der beste Schlüssel zum Menschenverständnis. Doch Kommunikation selbst ist nicht genug, um tiefe Beziehungen mit anderen zu bilden. Im Umgang mit verschiedenen Menschentypen müssen wir nach passenden Kommunikationsmitteln greifen. Es gibt doch allgemein geltende wirksame Methoden und Sprachmittel für den erfolgreichen Umgang mit anderen...
        </p>
        <p>
            <i>...respektvoller Umgang</i>
        </p>

        <p className='post-paragraph'>
            Jede Person verdient Respekt. Wenn wir mit solcher Überzeugung ins Gespräch treten, werden wir die Würde des anderen nicht verletzen. In einem Dialog kann der respektvolle Umgang durch passende Anrede und den often Augenkontakt geäußert werden. Die Basis einer respektvollen Kommunikation stellt auch das aufmerksame Zuhören des Gesprächspartners dar.
        </p>

        <p className='post-paragraph'>
            <i>...taktvolle Kritikäußerung</i>
        </p>

        <p className='post-paragraph'>
            Fehler sind ein untrennbarer Teil der Menschennatur. Auf dieser Wahrheit beruht das Toleranzprinzip. Es lehrt uns, bestimmtes Fehlverhalten der anderen, das wir eigentlich nicht persönlich annehmen sollten, zu verstehen.  Wir können uns aber nicht weiterentwickeln, wenn wir auf das falsche Verhalten einander nicht aufweisen. In diesem Fall ist eine negative Kritik nötig. Dabei ist es aber wichtig, auf eine taktvolle Weise auf das, was uns stört, aufzuweisen. Diese Art des Sprechens hat Wurzeln in der Philosophie des Stoizismus, die weise Redensart befürwortet. „Wenn wir weise kommunizieren wollen, müssen wir die Sachverhalte angemessen ausdrücken.“ Deshalb kann bei der Kritikäußerung der folgende Tipp hilfreich sein: Statt direkte Klagen und Du-Form zu verwenden, sollte man lieber aus der Ich-Position sprechen. Beispielsweise, wenn uns jemand ständig Privatfragen stellt, können wir folgenderweise erklären, warum die für uns nicht akzeptabel sind:
            <b><i>„Ich fühle mich nicht angenehm, wenn du mir solche Fragen stellst, weil ich einige Sachen nur für mich selbst behalten möchte.“</i></b>
            Auf diese Weise wird sich die Person nicht angegriffen fühlen und wird wahrscheinlicher den Grund unserer Frustration verstehen.
        </p>

        <p>
            <i>...würdevolles Diskutieren</i>
        </p>

        <p className='post-paragraph'>
            Die Ursache fast aller Konfliktsituationen sind unterschiedliche Einstellungen der Menschen. Wir sollten bedenken, dass im Hintergrund unserer Nichtübereinstimmungen unterschiedliche Erziehungsmethoden und Lebensumstände, in denen wir aufgewachsen sind, stehen. Darum versuchen wir, den Standpunkt anderer Person zu verstehen. Auf diese Weise bleiben wir ruhig und bodenständig in jedem Dialog. Sokrat, als Meister konstruktiver Dialoge, „wurde nie wütend in einem Gespräch”. “Er blieb immer höflich und hat sich davon abgehalten, zu scharf zu sprechen, auch wenn er beleidigt wurde. Viele Beleidigungen hat er geduldig ertragen und hat es trotzdem geschafft, mit Uneinigkeiten ruhig und auf vernünftige Weise klarzukommen.“ Mithilfe folgender Sätze können wir die andere Person in ihrer Wut entwaffnen und selbst würdig während einer Diskussion bleiben:
        </p>

        <p><b><i>Ich verstehe deinen Standpunkt, trotzdem denke ich anders.</i></b></p>
        <p><b><i>Meine Überzeugung kommt aus der Erfahrung, die ich hatte...</i></b></p>
        <p><b><i>Erlaube mir, meine Einstellung zu erklären.</i></b></p>
        <p><b><i>Es ist in Ordnung, dass wir anders den Sachverhalt betrachten.</i></b></p>


        <i>...Anforderungen geben</i>

        <p className='post-paragraph'>

            Menschliches Ego erträgt keinen Imperativ. Deshalb sollte man in der Kommunikation mit anderen Befehle vermeiden. Wenn wir andere bewegen wollen, etwas zu tun, dann halten wir uns am besten an goldenes Wort „bitte“. Mit dem goldenen Wort in der Kombination mit dem Konjunktiv erzielen wir das Gewünschte in jedem Gespräch.
            Bsp. <b><i>„Könntest du bitte meinen Text noch einmal lesen?“</i></b> oder <b><i>„Könntest du bitte heute den Einkauf erledigen?“</i></b> Solche Ansprächsweise können wir am besten in kommunikativen Situationen in den Dienstleistungen umsetzen, beispielsweise in Restaurants oder beim Einkauf. Obwohl die Aufgabe eines Kellners ist, uns die Bestellung zu bringen, heißt es nicht, dass wir unhöflich sein sollen.
            <b><i>„Könnten Sie mir die Speisekarte bringen, es wäre sehr nett von Ihnen.“</i></b>,
            klingt viel höflicher als ein befehlender Ton. Wenn Sie nächstes Mal einen müden Kellner oder Verkäufer treffen, geben Sie Ihr Bestes!
        </p>


    </div>)
}