import React from 'react';
import { NavLink } from 'react-router-dom';
import categories from '../../data/categories';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Navigation.css'
import FlagGroup from '../FlagGroup/FlagGroup';
import { LanguageContext } from '../../contexts/LanguageContext';

export default function Navigation() {

    const { language } = React.useContext(LanguageContext)

    function getCategoryTitle(category) {
        if (language.language === 'de') {
            return category.de.title;
        } else if (language.language === 'en') {
            return category.en.title;
        } else {
            return category.ba.title;
        }
    }

    function getLogo() {
        function getLogoSuperscript() {
            if (language.language === 'de') {
                return "Die Schönheit der Sprache"
            } else if (language.language === 'en') {
                return "The Beauty of Language"
            } else {
                return "Ljepota jezika"
            }
        }

        function getLogoSubscript() {
            if (language.language === 'de') {
                return "von Nermana"
            } else if (language.language === 'en') {
                return "by Nermana"
            } else {
                return "sa Nermanom"
            }
        }

        return <div className='logo-container'><span className="logo">{getLogoSuperscript()}</span><span className="logo-subscript">{getLogoSubscript()}</span></div>
    }

    function getAboutMe() {
        if (language.language === 'de') {
            return "Über mich";
        } else if (language.language === 'en') {
            return "About me";
        } else {
            return "O meni";
        }
    }

    return (
        <div className='container'>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Container fluid>
                    <Navbar.Brand as={NavLink} to="/">{getLogo()}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            navbarScroll
                        >

                            {
                                categories.map(category =>

                                    <Nav.Link key={category.id} as={NavLink} to={'/categories/' + category.id}>
                                        {getCategoryTitle(category)}
                                    </Nav.Link>
                                )
                            }

                            <Nav.Link as={NavLink} to="/about-me">
                                {getAboutMe()}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className='flag-group-navigation'><FlagGroup /></div>

                </Container>
            </Navbar >

        </div>
    )
}








