import React from 'react';
import './HumanUnderstanding.css';

export default function HumanUnderstandingEn() {

    return (<div class='post-body'>
        <h1 className='post-title'>Language as a medium for human understanding</h1>
        <img className='post-headline-image' src={process.env.PUBLIC_URL + '/images/posts/human-understanding/sokrat-wide.webp'} alt="bookshelf" />

        <p className='post-paragraph'>
            No one possesses the gift for mind reading. The lack of communication is the reason for most of the misunderstandings among human beings and the key for understanding the others is a talk. But communication itself is not enough for us to build deep connections with each other. In contact with different kinds of people we have to use appropriate communication instruments. Although, there are some universal efficient methods and language mediums for successful relations with others.
        </p>
        <p>
            <i>…respectful behavior</i>
        </p>

        <p className='post-paragraph'>
            Every person deserves respect. When we make a conversation based on this belief, we will not allow ourselves to hurt the other person's dignity. A respect toward the other person during the conversation can be expressed through the appropriate title and often eye contact. Of course, the basis of every respectful conversation is carefully listening to our dialog partner.
        </p>

        <p className='post-paragraph'>
            <i>…tactful critic expression</i>
        </p>

        <p className='post-paragraph'>
            Mistakes are an unavoidable part of human nature. The principle of tolerance is based on this truth. It teaches us to understand the wrong human behavior which we should not take personally. Although, we cannot develop ourselves, if we don't point out the mistakes to each other. In this case we have to express the negative criticism. By expressing what bothers us, it is important to do it in a tactful way. Such a style of communication has its roots in the philosophy of stoicism, which favors a wise way of speaking. “If we want to talk wisely, we have to express things appropriately.” This advice can be useful, when we want to express our indignation: instead of criticizing directly and using the you-form, we should speak from the I-perspective. For example, if someone is always asking us private questions, we can explain why it is inappropriate for us with the following sentence:
            <b><i>“I don't feel comfortable when you’re asking me these questions, because there are things that I want to keep only for myself.”</i></b>
            In this way the person will not feel attacked and it is more probable that she/he will understand the reason for our frustration.        </p>

        <p>
            <i>…dignified discussion </i>
        </p>

        <p className='post-paragraph'>
            The reasons for many conflict situations are people's different points of views. We should consider that in the background of our disagreements there are usually different upbringing styles and circumstances, within which we had been growing up. That’s why we should try to understand different mindsets. Then we will be able to stay calm and grounded in every dialogue. Sokrat, the master of constructive dialogues, “had never become furious during the conversation”. “He always stayed polite and restrained himself from speaking too harshly, even when he got offended. He patiently bore many offenses and regardless of them he successfully got to grips with most of the disagreements in a calm and reasonable way.” Using following sentences we can disarm a person in the state of anger and keep our dignity in the conversation:
        </p>

        <p><b><i>I understand your point of view, but I think differently.</i></b></p>
        <p><b><i>My belief comes from the experience I had…</i></b></p>
        <p><b><i>Allow me to express my opinion…</i></b></p>
        <p><b><i>It's fine if we don't think the same of that issue.</i></b></p>


        <i>…expressing requests</i>

        <p className='post-paragraph'>

            The human ego cannot stand imperative. For that reason we should avoid demandings in the conversation with others. If we want other people to do something, it would be best to keep on the golden word “please”. With polite requests we will get what we want in every conversation.
            Example: <b><i> “Could you please read my text one more time?”</i></b> or <b><i>“Could you please go shopping today?”</i></b> Such a way of speaking we can apply the best in the communicative situations in the services, for example in the restaurants or by shopping. Although the job of a waitress is to bring us our order, it doesn't mean we should be impolite.
            <b><i>“If you could bring me a menu, it would be really nice of you.”</i></b>,
            sounds much more polite than some demanding tone. So, the next time you meet a tired waitress or a salesman, you give your best!
        </p>


    </div>)
}