import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Category from './components/Category/Category';
import AboutMe from './components/AboutMe/AboutMe'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import PowerOfLanguageUse from './components/posts/PowerOfLanguageUse/PowerOfLanguageUse';
import VocabularyInLanguageFamilier from './components/posts/VocabularyInLanguageFamilies/VocabularyInLanguageFamilies';
import SimilarWords from './components/posts/SimilarWords/SimilarWords';
import GiftOfTime from './components/posts/GiftOfTime/GiftOfTime';
import HumanUnderstanding from './components/posts/HumanUnderstanding/HumanUnderstanding';
import './i18n';
import SentenceBuilding from './components/posts/SentenceBuilding/SentenceBuilding';
import KindWords from './components/posts/KindWords/KindWords';
import IdealOfGoodness from './components/posts/IdealOfGoodness/IdealOfGoodness';
import NounArticles from './components/posts/NounArticles/NounArticles';
import ExercisingLanguageSkills from './components/posts/ExercisingLanguageSkills/ExercisingLanguageSkills';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<App />} />
          <Route path="/categories/:id" element={<Category />} />
          <Route path="/categories/1/posts/1" element={<PowerOfLanguageUse />} />
          <Route path="/categories/1/posts/2" element={<HumanUnderstanding />} />
          <Route path="/categories/1/posts/3" element={<KindWords />} />
          <Route path="/categories/2/posts/1" element={<VocabularyInLanguageFamilier />} />
          <Route path="/categories/2/posts/2" element={<SentenceBuilding />} />
          <Route path="/categories/2/posts/3" element={<NounArticles />} />
          <Route path="/categories/2/posts/4" element={<ExercisingLanguageSkills />} />
          <Route path="/categories/3/posts/1" element={<SimilarWords />} />
          <Route path="/categories/4/posts/1" element={<GiftOfTime />} />
          <Route path="/categories/4/posts/2" element={<IdealOfGoodness />} />
          <Route path="about-me" element={<AboutMe />} />
        </Route>
      </Routes>
    </BrowserRouter>

  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
